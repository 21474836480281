import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class FloatCart extends React.PureComponent {
  render() {
    const { classes, onClick, cart } = this.props;
    return (
      <IconButton className={classes.wrapper} onClick={onClick}>
        <ShoppingCartIcon className={classes.cartIcon} />
        <Badge badgeContent={cart.reduce((count, item) => count + item.count, 0)} className={classes.badge} color="secondary">
          <span />
        </Badge>
      </IconButton>
    );
  }
}

FloatCart.propTypes = {
  cart: PropTypes.arrayOf(PropTypes.shape()),
  classes: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
};

FloatCart.defaultProps = {
  cart: [],
  onClick: () => null,
};

export default withStyles(styles)(FloatCart);
