
import { footerHeight } from 'themes/variables';
import { wrapper, mediaLarge } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...wrapper(),
  },
  title: {
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: '200%',
  },
  content: {
    minHeight: `calc(100vh - ${footerHeight}px)`,
    paddingTop: 56,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    overflowX: 'auto',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  submit: {
    width: '100%',
  },
  more: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  padding: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  middle: {
    textAlign: 'center',
  },
  iconText: {
    fontSize: '750%',
    color: theme.palette.primary.main,
  },
  ...mediaLarge({
    inner: {
      width: '100%',
      maxWidth: 1200,
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    submit: {
      alignSelf: 'flex-end',
      width: 'auto',
    },
  }),
});

export const tableCellStyles = theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  body: {
    fontSize: 14,
  },
});
