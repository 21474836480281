
import { mediaLarge, mediaMedium, mediaXlarge, mediaXXlarge } from 'themes/mixins';

const baseExtraMidiaMixin = {
  categoryBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

export default theme => ({
  categoryTitle: {
    fontWeight: 500,
    fontSize: '1.25rem',
    textAlign: 'left',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    borderTop: `2px solid ${theme.palette.primary.main}`,
    // borderBottom: `2px solid ${theme.palette.primary.main}`,
    width: '100%',
  },
  ...mediaMedium({
    cartListItem: {
      width: '50%',
    },
    ...baseExtraMidiaMixin,
  }),
  ...mediaLarge({
    cartListItem: {
      width: '33%',
    },
    ...baseExtraMidiaMixin,
  }),
  ...mediaXlarge({
    cartListItem: {
      width: '25%',
    },
    ...baseExtraMidiaMixin,
  }),
  ...mediaXXlarge({
    cartListItem: {
      width: '25%',
    },
    ...baseExtraMidiaMixin,
  }),
});
