import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import SignForm from 'components/containers/forms/Signin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { signin, getDinerInfo } from 'apis/services/signin';
import { omit, isEmpty } from 'lodash';
import { notification } from 'antd';
import { bindActionCreators } from 'redux';
import { setAuth, saveAuth } from 'status/partial/auth';
import { setDiner, saveDiner } from 'status/partial/diner';
// import { push } from 'react-router-redux';
import { push } from 'connected-react-router';
import styles from './styles';

class Signin extends React.PureComponent {
  async submit(values) {
    const { router } = this.props;
    const to = qs.parse(router.location.search.slice(1)).to;
    // signin
    const signinData = await signin(omit(values, ['saved'])).then((res) => {
      this.props.setAuth(res.data);
      if (values.saved) {
        this.props.saveAuth(res.data);
      }
      return res.data;
    }).catch(() => {
      notification.error({
        message: 'brugernavn eller adgangskode er forkert.',
        duration: 2,
      });
    });

    // get the diner info
    if (signinData != null) {
      getDinerInfo({
        user: signinData.user.pk,
      }).then((res) => {
        const target = res.data.results[0];
        this.props.setDiner(target);
        if (values.saved) {
          this.props.saveDiner(target);
        }
        if (to != null) {
          this.props.dispatch(push({
            pathname: to,
          }));
        } else {
          /*
          this.props.dispatch(push({
            pathname: '/',
          }));
          */
          this.props.onFinish();
        }
      }).catch(() => {
        notification.error({
          message: 'brugernavn eller adgangskode er forkert.',
          duration: 2,
        });
      });
    }
  }

  render() {
    const { classes, router, cart, auth } = this.props;
    return (
      <div className={classes.formWrapper}>
        <SignForm
          onSubmit={values => this.submit(values)}
          to={router.location.search}
          booking={cart.length > 0 && isEmpty(auth)}
        />
      </div>
    );
  }
}

Signin.propTypes = {
  classes: PropTypes.shape().isRequired,
  router: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatch: PropTypes.func.isRequired,
  saveAuth: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired,
  setDiner: PropTypes.func.isRequired,
  saveDiner: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    router: state.router,
    cart: state.cart,
    auth: state.auth,
  }),
  dispatch => ({
    ...bindActionCreators({
      setAuth,
      saveAuth,
      setDiner,
      saveDiner,
    }, dispatch),
    dispatch,
  }),
)(withStyles(styles)(Signin));
