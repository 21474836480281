
import { footerHeight, colors } from 'themes/variables';
import { mediaLarge, mediaXlarge, mediaXXlarge, scrollbar } from 'themes/mixins';

const baseExtraMidiaMixin = {
  wrapper: {
    paddingBottom: 0,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
  },
  contentWrapper: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    overflowY: 'scroll',
    ...scrollbar(theme),
  },
  content: {
    minHeight: `calc(100vh - ${footerHeight}px)`,
  },
  aside: {
    display: 'none',
  },
  cardWrapper: {
    flex: '1 1 auto',
  },
  placeholder: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  deliveryInfo: {
    marginTop: theme.spacing(2),
  },
  deliveryHead: {
    ...theme.mixins.toolbar,
    display: 'none',
  },
  nonocoTips: {
    color: colors.focus,
  },
  /*
  ...mediaMedium({
    ...baseExtraMidiaMixin,
    display: 'flex',
  }),
  */
  ...mediaLarge({
    ...baseExtraMidiaMixin,
  }),
  ...mediaXlarge({
    ...baseExtraMidiaMixin,
  }),
  ...mediaXXlarge({
    ...baseExtraMidiaMixin,
  }),
});
