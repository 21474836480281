
export default theme => ({
  light: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
  },
});
