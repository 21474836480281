import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

class OfflineModal extends React.PureComponent {
  handleClose = () => {
    this.props.onClose();
  }

  render() {
    const { open, classes, content } = this.props;

    return (
      <Dialog
        open={open}
        onClose={() => null}
      >
        <DialogTitle>Notification</DialogTitle>
        <DialogContent className={classes.content}>
          <Typography>
            { content }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="primary"
          >
            Luk
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

OfflineModal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.string,
};

OfflineModal.defaultProps = {
  open: false,
  content: '',
  onClose: () => null,
};

export default withStyles(styles)(OfflineModal);
