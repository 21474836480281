import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import formatCurrency from 'utils/formatCurrency';

import { isEmpty, get } from 'lodash';
import styles from './styles';

const PLACE_HOLDER_IMG = 'https://via.placeholder.com/640x360?text=Image+comming+soon';

class MenuCard extends React.PureComponent {
  onAdd = (e) => {
    e.stopPropagation();
    this.props.onAdd(this.props.item);
  }

  onClick = () => {
    this.props.onClick(this.props.item);
  }

  onImgError = (e) => {
    e.target.src = PLACE_HOLDER_IMG;
  }

  render() {
    const { classes, title, price, desc, more, discount, pic } = this.props;
    return (
      <Card className={classes.card}>
        <div className={classes.content}>
          {
            (!isEmpty(discount) && get(discount, 'promotion_type', '') === 'discount') &&
              <Typography className={classes.promotionTip}>
                {`SPAR ${(1 - get(discount, 'detail.value', 0)) * 100}%`}
              </Typography>
          }

          <CardMedia
            component="img"
            className={classes.media}
            image={isEmpty(pic) ? PLACE_HOLDER_IMG : pic}
            title={title}
            onClick={this.onClick}
            onError={this.onImgError}
          />
          <CardContent className={classes.contentInner}>
            <CardHeader
              title={title}
              classes={{ root: classes.header, title: classes.headerTitle }}
              onClick={this.onClick}
            />
            <Typography
              component="p"
              className={classes.desc}
              onClick={this.onClick}
            >
              {desc}
            </Typography>
            <div className={classes.priceWrapper}>
              {
                !isEmpty(discount) ?
                  <React.Fragment>
                    <Typography variant="body1" className={classes.originPrice}>
                      <del>DKK {formatCurrency(price)} kr</del>
                    </Typography>
                    <Typography variant="subtitle1" className={classes.price}>
                      {
                        more &&
                          <span>Fra </span>
                      }
                      <span>DKK {formatCurrency(price * discount.detail.value)} kr</span>
                    </Typography>
                  </React.Fragment>
                  :
                  <Typography variant="subtitle1" className={classes.price}>
                    {
                      more &&
                        <span>Fra </span>
                    }
                    <span>DKK {formatCurrency(price)} kr</span>
                  </Typography>
              }
            </div>
            <div>
              <React.Fragment>
                {
                  more ?
                    <IconButton onClick={this.onAdd} className={classes.action}>
                      <AddCircleIcon className={classes.addIconMore} />
                    </IconButton>
                    :
                    <IconButton onClick={this.onAdd} className={classes.action}>
                      <AddCircleIcon className={classes.addIcon} />
                    </IconButton>
                }
              </React.Fragment>
            </div>
          </CardContent>
        </div>
      </Card>
    );
  }
}

MenuCard.propTypes = {
  classes: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
  title: PropTypes.string,
  price: PropTypes.number,
  desc: PropTypes.string,
  pic: PropTypes.string,
  more: PropTypes.bool,
  discount: PropTypes.shape(),
};

MenuCard.defaultProps = {
  onAdd: () => null,
  onClick: () => null,
  title: '',
  desc: '',
  price: -1,
  pic: '',
  more: false,
  discount: {},
};

export default withStyles(styles)(MenuCard);
