import { mediaLarge, scrollbar } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'none',
  },
  ...mediaLarge({
    wrapper: {
      display: 'block',
      boxShadow: theme.shadows[1],
      position: 'sticky',
      top: 0,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      overflowY: 'scroll',
      // backgroundColor: theme.palette.background.secondary,
      backgroundColor: theme.palette.background.paper,
      ...scrollbar(theme),
    },
  }),
});
