
import {
  setCarrierBagAtLeastOne,
} from 'status/partial/cart';
import { isEmpty, once } from 'lodash';

// O(N)
export function filterMenu(menu, categoryId) {
  if (categoryId === -1) {
    return menu;
  }
  return menu.filter(item => item.store_category.id === categoryId);
}

// O(N+M)
export function filterCategory(menu, category) {
  const menuHash = {};
  menu.forEach((item) => {
    if (menuHash[item.store_category.id] !== true) {
      menuHash[item.store_category.id] = true;
    }
  });
  return category.filter(item => menuHash[item.id]);
}

export function hasSecondaryMenu(merchandise) {
  return merchandise.merchandisespec_set.length !== 1 ||
    !isEmpty(merchandise.extras) ||
    !isEmpty(merchandise.attach_properties) ||
    !isEmpty(merchandise.parts);
}

export function isEnableTheCarrierBagFeature(merchandiseCarrierBag, carrierbagsInForce) {
  if (
    isEmpty(merchandiseCarrierBag) ||
    !carrierbagsInForce
  ) {
    return false;
  }
  return true;
}

export const initCarrierBagInCart = once((carrierBagSPU, dispatch) => {
  dispatch(
    setCarrierBagAtLeastOne(
      carrierBagSPU[0].merchandisespec_set[0],
      carrierBagSPU[0].store_category,
    ),
  );
});
