import { getSessionStorage } from 'utils/sessionStorage';

export const SESSTION_KEY = 'do_not_show_nonoco_tips';

const CLOSE_NONOCO_TIPS = Symbol('OPEN_NONOCO_TIPS');
export const actionTypes = {
  CLOSE_NONOCO_TIPS,
};

function getInitalShowNonocoTips() {
  const isOpened = getSessionStorage(SESSTION_KEY);
  if (isOpened != null) {
    return false;
  }
  return true;
}

export const initialState = getInitalShowNonocoTips();

export const closeNonocoTips = () => ({ type: CLOSE_NONOCO_TIPS, payload: false });

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_NONOCO_TIPS:
      return action.payload;
    default:
      return state;
  }
};
