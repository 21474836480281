import { mediaLarge } from 'themes/mixins';
import { colors } from 'themes/variables';

export default theme => ({
  wrapper: {
    display: 'flex',
    boxShadow: theme.shadows[1],
    position: 'sticky',
    top: 0,
    width: '100vw',
    zIndex: 10,
    // backgroundColor: theme.palette.background.secondary,
    backgroundColor: theme.palette.background.paper,
    height: 64,
    overflow: 'hidden',
  },
  scroll: {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  arrowBack: {
    color: colors.secondary,
    textAlign: 'left',
    height: 'auto',
  },
  arrowForward: {
    color: colors.secondary,
    height: 'auto',
    textAlign: 'right',
    transform: 'translate(5px, 0)',
  },
  ...mediaLarge({
    wrapper: {
      display: 'none',
    },
  }),
});

