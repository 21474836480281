import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import className from 'classnames';
import { Field, reduxForm, propTypes, Form, formValueSelector, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { range, get, isEmpty, padStart } from 'lodash';
import { connect } from 'react-redux';
import validator from 'utils/validator';
import extractOpenAndCloseTime from 'utils/extractOpenAndCloseTime';
import DateField from 'components/presentations/Fields/DateField';
import styles from './styles';

const FORM_NAME = 'time';
const ASAP_VALUE = 'Hurtigst Muligt';

function getStartTimeAndEndTime(
  startTime,
  endTime,
  opening,
  currentDate,
  currentTime,
  waitingDuration,
) {
  let start = moment(`${currentDate} ${startTime}`);
  let isClosedInCurrentDate = false;
  const current = moment(`${moment().format('YYYY-MM-DD')} ${currentTime}`);
  const end = moment(`${currentDate} ${endTime}`);

  if (opening) {
    const spareMinute = waitingDuration * 60 * 1000;
    const nowDateTime = current.valueOf();
    start = moment(nowDateTime + spareMinute);
  } else if (current >= end) {
    start = end;
    isClosedInCurrentDate = true;
  }

  return {
    start,
    end,
    isClosedInCurrentDate,
  };
}

function getMinutesRange(
  start,
  end,
  selectedDate,
  selectedHours,
  isClosedInCurrentDate = false,
) {
  if (isClosedInCurrentDate) {
    return [];
  }
  const timeRange = range(0, 60, 5).map(item => padStart(item, 2, '0'));
  const filteredTimeRange = timeRange.filter((item) => {
    const selectTime = moment(`${selectedDate} ${isEmpty(selectedHours) ? '00' : selectedHours}:${item}:00`);
    if (selectTime.valueOf() < start.valueOf()) {
      return false;
    }
    if (selectTime.valueOf() > end.valueOf()) {
      return false;
    }
    return true;
  });
  return filteredTimeRange;
}

function MinutesField({
  meta,
  input,
  timeRange,
  disabled,
}) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      select
      label="minutter"
      fullWidth
      margin="normal"
      disabled={disabled}
      {...input}
    >
      {
        timeRange.map(time => (
          <MenuItem value={time} key={`minutter_${time}`}>
            {time}
          </MenuItem>
        ))
      }
    </TextField>
  );
}

MinutesField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  timeRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
};

MinutesField.defaultProps = {
  disabled: false,
};

function getHourText(hourMoment) {
  return padStart(hourMoment.hours(), 2, '0');
}

function getHoursRange(
  start,
  end,
  date,
  isClosedInCurrentDate = false,
) {
  let loopIndex = start;
  const timeRange = [];
  if (isClosedInCurrentDate) {
    return [];
  }
  while (moment(loopIndex).hours() <= end.hours()) {
    const target = moment(loopIndex);
    const minutesRange = getMinutesRange(start, end, date, getHourText(target));
    if (minutesRange.length !== 0) {
      timeRange.push(moment(loopIndex));
    }
    if (moment(loopIndex).hours() === 23) {
      break;
    }
    loopIndex += 60 * 60 * 1000;
  }
  return timeRange;
}

function HoursField({
  meta,
  input,
  timeRange,
  disabled,
}) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      select
      label="tids"
      fullWidth
      margin="normal"
      disabled={disabled}
      {...input}
    >
      {
        timeRange.map((item) => {
          const time = item.format('HH');
          return (
            <MenuItem value={time} key={`tids_${time}`}>
              { time }
            </MenuItem>
          );
        })
      }
    </TextField>
  );
}

HoursField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  timeRange: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool,
};

HoursField.defaultProps = {
  disabled: false,
};

function DescriptionField({ input }) {
  return (
    <TextField
      multiline
      rows="4"
      fullWidth
      maxLength="1024"
      {...input}
    />
  );
}

DescriptionField.propTypes = {
  input: PropTypes.shape().isRequired,
};

function ASAPField({ input }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={input.value}
          {...input}
        />
      }
      label={ASAP_VALUE}
    />
  );
}

ASAPField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

function isOpening(date, openTime, closeTime) {
  if (date !== moment().format('YYYY-MM-DD')) {
    return false;
  }
  if (openTime == null || closeTime == null) {
    return false;
  }
  const target = moment().toDate();
  const start = moment(`${date} ${openTime}`).toDate();
  const end = moment(`${date} ${closeTime}`).toDate();
  if (target > start && target < end) {
    return true;
  }
  return false;
}

function parseDate(value) {
  try {
    return moment(value).format('YYYY-MM-DD');
  } catch (e) {
    return '';
  }
}

class Time extends React.PureComponent {
  constructor(props) {
    super(props);
    const now = moment();
    this.currentDate = now.format('YYYY-MM-DD');
    this.currentTime = now.format('HH:mm:ss');
  }

  componentDidMount() {
    this.setAsapValue();
  }

  componentWillUnmount() {
    this.setAsapValue();
  }

  onDateChange = () => {
    const { dispatch } = this.props;
    dispatch(change(FORM_NAME, 'hours', ''));
    dispatch(change(FORM_NAME, 'minutes', ''));
    // If here have not the setTimeout statement,
    // the callback which name is 'setAsapValue' just only get the previous asap value
    // from the props.
    setTimeout(this.setAsapValue, 1);
  }

  onHoursChange = () => {
    const { dispatch } = this.props;
    dispatch(change(FORM_NAME, 'minutes', ''));
  }

  onAsapChange = (e, newValue) => {
    const { dispatch } = this.props;
    if (newValue) {
      // If here have not the setTimeout statement,
      // the callback which name is 'setAsapDateTime' just only can get the previous asap value
      // from the props.
      setTimeout(this.setAsapDateTime, 1);
    } else {
      dispatch(change(FORM_NAME, 'hours', ''));
      dispatch(change(FORM_NAME, 'minutes', ''));
    }
  }

  setAsapDateTime = () => {
    const { values, store, dispatch } = this.props;
    const { asap } = values;
    const startAndEndTime = extractOpenAndCloseTime(store.opening_hours, get(values, 'date', new Date()));
    const opening = isOpening(
      values.date,
      startAndEndTime.starttime,
      startAndEndTime.endtime,
    );
    if (asap && opening) {
      dispatch(change(FORM_NAME, 'hours', '00'));
      dispatch(change(FORM_NAME, 'minutes', '00'));
    }
  }

  setAsapValue = () => {
    const { values, store, dispatch } = this.props;
    const startAndEndTime = extractOpenAndCloseTime(store.opening_hours, get(values, 'date', new Date()));
    const opening = isOpening(
      values.date,
      startAndEndTime.starttime,
      startAndEndTime.endtime,
    );

    dispatch(change(FORM_NAME, 'asap', opening));
    this.setAsapDateTime();
  }

  shouldDisableDate = (day) => {
    const { store } = this.props;
    const weekMap = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ];
    const weekDay = weekMap[day.day()];
    const { endtime, starttime } = store.opening_hours[weekDay];
    // The format is HH:mm:ss
    const endtimeList = endtime.split(':');
    const starttimeList = starttime.split(':');
    if (
      starttimeList[0] === endtimeList[0] &&
      starttimeList[1] === endtimeList[1] &&
      parseInt(endtimeList[2], 10) - parseInt(starttimeList[2], 10) <= 2
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, handleSubmit, values, store, waitingDuration } = this.props;
    const startAndEndTime = extractOpenAndCloseTime(store.opening_hours, get(values, 'date', new Date()));
    const opening = isOpening(
      values.date,
      startAndEndTime.starttime,
      startAndEndTime.endtime,
    );

    const { start, end, isClosedInCurrentDate } = getStartTimeAndEndTime(
      startAndEndTime.starttime,
      startAndEndTime.endtime,
      opening,
      values.date,
      this.currentTime,
      waitingDuration,
    );

    const minutesRange = getMinutesRange(
      start,
      end,
      values.date,
      values.hours,
      isClosedInCurrentDate,
    );
    const hoursRange = getHoursRange(start, end, values.date, isClosedInCurrentDate);

    const { asap } = values;
    return (
      <div>
        <Typography
          variant="h6"
          align="center"
          className={className(classes.padding, classes.title, classes.label)}
        >
          Bekræft din samlingstid
        </Typography>
        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
          <Field
            name="date"
            component={DateField}
            label={'Dato'}
            parse={parseDate}
            disablePast
            onChange={this.onDateChange}
            shouldDisableDate={this.shouldDisableDate}
          />
          {
            isEmpty(hoursRange) && !asap &&
              <Typography
                variant="caption"
                align="left"
                color="primary"
                className={className(classes.padding, classes.title, classes.label)}
              >
                Vælg en anden dato tak.
              </Typography>
          }
          {
            opening &&
              <div>
                <Field
                  name="asap"
                  component={ASAPField}
                  onChange={this.onAsapChange}
                />
              </div>
          }
          {
            !(asap && opening) &&
              <Grid container spacing={5} className={classes.padding}>
                <Grid item xs={6}>
                  <Field
                    name="hours"
                    component={HoursField}
                    timeRange={hoursRange}
                    onChange={this.onHoursChange}
                    disabled={isEmpty(hoursRange)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="minutes"
                    component={MinutesField}
                    timeRange={minutesRange}
                    disabled={isEmpty(minutesRange)}
                  />
                </Grid>
              </Grid>
          }
          <Typography variant="caption" className={className(classes.padding, classes.label)}>
            Har du allergi eller andet kostbehov? Efterlad en kommentar til restauranten.
          </Typography>
          <Field
            name="description"
            component={DescriptionField}
          />
          <div className={classes.bottom}>
            <Button color="primary" variant="contained" fullWidth type="submit">Gå til betaling</Button>
          </div>
        </Form>
      </div>
    );
  }
}

Time.propTypes = {
  ...propTypes,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  waitingDuration: PropTypes.number.isRequired,
};


const selector = formValueSelector(FORM_NAME);
export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    date: moment().format('YYYY-MM-DD'),
    hours: '',
    minutes: '',
    description: '',
    asap: false,
  },
  validate(values) {
    const result = {};

    if (!validator.isNotEmpty(values.date)) {
      result.date = 'Vælg venligst en dato';
    }

    if (!validator.isNotEmpty(values.hours)) {
      result.hours = 'Vælg venligst tid';
    }

    if (!validator.isNotEmpty(values.minutes)) {
      result.minutes = 'Vælg venligst tid';
    }

    return result;
  },
})(connect(state => ({
  values: {
    date: selector(state, 'date'),
    hours: selector(state, 'hours'),
    minutes: selector(state, 'minutes'),
    description: selector(state, 'description'),
    asap: selector(state, 'asap'),
  },
  store: state.store,
}), dispatch => ({
  dispatch,
}))(withStyles(styles)(Time)));
