import { get } from 'lodash';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

function throwSetDefaultColorError() {
  throw new Error('Adjust default color is not allow.');
}

// class-methods-use-this rule doesn't consider existence of getters and setters
// https://github.com/eslint/eslint/issues/7911
class Palette {
  /* eslint-disable class-methods-use-this */
  constructor(theme = {}) {
    this._theme = theme;

    this._primary = amber[600];
    this._secondary = grey[500];

    this._focus = red[500];
    this._dark = 'rgba(0, 0, 0, 0.87)';
    this._success = green[500];

    this._undertone = grey[50];
  }

  set theme(theme) {
    this._theme = theme;
  }
  get theme() {
    return this._theme;
  }

  get primary() {
    return get(this._theme, 'primary', this._primary);
  }
  set primary(value) {
    throwSetDefaultColorError();
  }

  get undertone() {
    return get(this._theme, 'undertone', this._undertone);
  }
  set undertone(value) {
    throwSetDefaultColorError();
  }

  get secondary() {
    return get(this._theme, 'secondary', this._secondary);
  }
  set secondary(value) {
    throwSetDefaultColorError();
  }

  get focus() {
    return get(this._theme, 'focus', this._focus);
  }
  set focus(value) {
    throwSetDefaultColorError();
  }

  get dark() {
    return get(this._theme, 'dark', this._dark);
  }
  set dark(value) {
    throwSetDefaultColorError();
  }

  get success() {
    return get(this._theme, 'success', this._success);
  }
  set success(value) {
    throwSetDefaultColorError();
  }
  /* eslint-enable class-methods-use-this */
}

export const colors = new Palette(/* {
  primary: '#007bff',
  undertone: '#343a40',
  secondary: grey[50],
  focus: '#dc3545',
  dark: 'rgba(0, 0, 0, 0.87)',
  success: green[500],
} */);

export const screens = {
  xxl: 1660,
  xl: 1280,
  lg: 960,
  md: 600,
};

export const footerHeight = 150;
export const fontSize = 14;
