import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import { routerReducer, routerMiddleware } from 'react-router-redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { createEpicMiddleware } from 'redux-observable';
import history from 'src/history';
import rootReducers from 'status/partial/reducers';
import rootEpics from 'status/partial/epics';
import saveOrderToSession from './middlewares/saveOrderToSession';
import saveAuthToLocal from './middlewares/saveAuthToLocal';
import saveDinerToLocal from './middlewares/saveDinerToLocal';
import saveStoreToSession from './middlewares/saveStoreToSession';
import saveMenuToSession from './middlewares/saveMenuToSession';
import savePromotionToSession from './middlewares/savePromotionToSession';
import saveNonocoTipsStateToSesstion from './middlewares/saveNonocoTipsStateToSesstion';

const reducers = combineReducers({
  form: formReducer,
  router: connectRouter(history),
  ...rootReducers,
});

let composeEnhancers = compose;
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: true });
}

const epicMiddleware = createEpicMiddleware();

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(
    routerMiddleware(history),
    epicMiddleware,
    saveOrderToSession,
    saveAuthToLocal,
    saveDinerToLocal,
    saveStoreToSession,
    saveMenuToSession,
    savePromotionToSession,
    saveNonocoTipsStateToSesstion,
  )),
);

epicMiddleware.run(rootEpics);

export default store;
