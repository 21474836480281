import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import VanillaTemp from './templates/vanilla';
import DefaultTemp from './templates/default';

class Index extends React.PureComponent {
  render() {
    const { configuration } = this.props;
    if (get(configuration, 'template_name', '') === 'vanilla') {
      return <VanillaTemp />;
    }
    return <DefaultTemp />;
  }
}

Index.propTypes = {
  configuration: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default connect(state => ({
  configuration: state.configuration,
}), dispatch => ({
  dispatch,
}))(Index);
