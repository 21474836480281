import React from 'react';
import PropTypes from 'prop-types';
// import { ConnectedRouter } from 'react-router-redux';
import { /* Route, */HashRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { ConnectedRouter } from 'connected-react-router';
import CookieConsent from 'components/presentations/CookieConsent';
import Index from 'views/Index';
import Signin from 'views/Signin';
import Signup from 'views/Signup';
import Booking from 'views/Booking';
import Time from 'views/Time';
import Purchase from 'views/Purchase';
import Term from 'views/Term';
import BookSuccess from 'views/BookSuccess';
import FreepayFail from 'views/FreepayFail';
import OrderPreviewer from 'views/OrderPreviewer';
import UserCenter from 'views/UserCenter';
import Password from 'views/Password';
import history from './history';

const routes = [
  {
    path: '/',
    component: Index,
    exact: true,
  },
  {
    path: '/signin',
    component: Signin,
  },
  {
    path: '/signup',
    component: Signup,
  },
  {
    path: '/set_password',
    component: Password,
  },
  {
    path: '/shipping',
    component: Booking,
  },
  {
    path: '/time',
    component: Time,
  },
  {
    path: '/purchase',
    component: Purchase,
  },
  {
    path: '/term',
    component: Term,
  },
  {
    path: '/done',
    component: BookSuccess,
  },
  {
    path: '/freepay_fail',
    component: FreepayFail,
  },
  {
    path: '/preview_order',
    component: OrderPreviewer,
  },
  {
    path: '/user',
    component: UserCenter,
  },
];

/*
export const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    exact={route.exact}
    render={props => (
      <route.component {...props} routes={route.routes} />
    )}
  />
);
*/


const RouteComponents = props => (
  <ConnectedRouter history={history}>
    <HashRouter>
      { renderRoutes(routes) }
      {/* routes.map(route => <RouteWithSubRoutes key={route.path} {...route} />) */}
      <CookieConsent
        store={props.store}
      />
    </HashRouter>
  </ConnectedRouter>
);

RouteComponents.propTypes = {
  store: PropTypes.shape({}).isRequired,
};


export default RouteComponents;
