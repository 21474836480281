import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import styles from './styles';

const Footer = props => (
  <footer className={props.classes.footer}>
    <Typography >Copyright © 2017-2022 {props.name} All rights reserved.</Typography>
    <Typography>
      { props.address } | TLF.: {props.cellphone} | E-MAIL: {props.email}
    </Typography>
    <div>
      <img className={props.classes.img} src={import('assets/images/dk.png')} alt="dk" />
      <img className={props.classes.img} src={import('assets/images/visa.png')} alt="visa" />
      <img className={props.classes.img} src={import('assets/images/mastercard.png')} alt="mastercard" />
      <img className={props.classes.img} src={import('assets/images/visa-e.png')} alt="visa-e" />
    </div>
  </footer>
);

Footer.propTypes = {
  classes: PropTypes.shape().isRequired,
  address: PropTypes.string,
  cellphone: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
};

Footer.defaultProps = {
  address: '',
  cellphone: '',
  email: '',
  name: 'E-Screen',
};

export default withStyles(styles)(Footer);
