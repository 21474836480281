import { colors } from 'themes/variables';

export default () => ({
  formWrapper: {
    maxWidth: 450,
    width: '100%',
    alignSelf: 'center',
  },
  errorInfo: {
    color: colors.focus,
  },
});
