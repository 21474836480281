import { colors, screens } from './variables';
import { scrollbar } from './mixins';
/*
export default function getInitConfigration(theme) {
  return {
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: colors.primary,
      },
      background: {
        ...theme.palette.background,
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 56,
      },
      '@media (min-width:600px)': {
        minHeight: 56,
      },
      minHeight: 56,
    },
    breakpoints: {
      values: {
        ...theme.breakpoints.values,
        ...screens,
      },
    },
    zIndex: {
      ...theme.zIndex,
      mobileStepper: 100,
      appBar: 110,
      drawer: 120,
      modal: 130,
      snackbar: 140,
      tooltip: 150,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            ...scrollbar,
          },
          h1: {
            color: 'unset',
          },
          h2: {
            color: 'unset',
          },
          h3: {
            color: 'unset',
          },
          h4: {
            color: 'unset',
          },
          h5: {
            color: 'unset',
          },
          h6: {
            color: 'unset',
          },
        },
      },
    },
  };
}
*/
export default function getInitConfigration() {
  return {
    palette: {
      primary: {
        main: colors.primary,
      },
    },
    toolbar: {
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 56,
        maxHeight: 56,
      },
      '@media (min-width:600px)': {
        minHeight: 56,
        maxHeight: 56,
      },
      minHeight: 56,
      maxHeight: 56,
    },
    breakpoints: {
      values: {
        ...screens,
      },
    },
    zIndex: {
      mobileStepper: 100,
      appBar: 110,
      drawer: 120,
      modal: 130,
      snackbar: 140,
      tooltip: 150,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            ...scrollbar,
          },
          h1: {
            color: 'unset',
          },
          h2: {
            color: 'unset',
          },
          h3: {
            color: 'unset',
          },
          h4: {
            color: 'unset',
          },
          h5: {
            color: 'unset',
          },
          h6: {
            color: 'unset',
          },
        },
      },
    },
  };
}
