import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty, keys, get, values, flatten, map, isEqual } from 'lodash';
import { findTargetPromotion } from 'utils/promotionTools';
import {
  hasSecondaryMenu,
} from 'views/index/templates/utils/menutools';
import MenuCard from 'views/index/templates/vanilla/components/MenuCard';
import styles from './styles';

// Sort the keys of merchandise list for fit the category list order.
// The category list order was provided from the server side.
export function getSortedMenuKeys(menu) {
  return keys(menu).sort((a, b) => {
    const firstRank = get(menu[a], '0.merchandise.store_category.ranking_index', 1000000);
    const secondRank = get(menu[b], '0.merchandise.store_category.ranking_index', 1000000);
    return firstRank - secondRank;
  });
}

// The third-party library called 'react-scrollspy' needs these ids.
export function extractCategoryId(
  menu,
  cateforyPrefix = 'category_',
  merchandiseWrapperSuffix = '_merchandise_wrapper',
) {
  return getSortedMenuKeys(menu).map(id => `${cateforyPrefix}${id}${merchandiseWrapperSuffix}`);
}

class MerchandiseList extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { menu } = this.props;
    const nextMenu = get(nextProps, 'menu', {});

    const categoryKeys = keys(menu).sort();
    const nextCategoryKeys = keys(nextMenu).sort();
    if (!isEqual(categoryKeys, nextCategoryKeys)) {
      return true;
    }

    const merchandiseList = flatten(values(menu));
    const nextMerchandiseList = flatten(values(nextMenu));
    if (merchandiseList.length !== nextMerchandiseList.length) {
      return true;
    }

    const merchandiseIdList = map(
      merchandiseList,
      item => ({
        merchandise: get(item, 'merchandise.id', 0),
        buffet: get(item, 'buffet.id', 0),
      })).sort((x, y) => x.merchandise - y.merchandise);
    const nextMerchandiseIdList = map(
      nextMerchandiseList,
      item => ({
        merchandise: get(item, 'merchandise.id', 0),
        buffet: get(item, 'buffet.id', 0),
      })).sort((x, y) => x.merchandise - y.merchandise);
    if (!isEqual(merchandiseIdList, nextMerchandiseIdList)) {
      return true;
    }

    return false;
  }

  render() {
    const {
      classes,
      onAdd,
      onShowInfo,
      menu,
      promotion,
      prefix,
      suffix,
    } = this.props;

    return (
      <div className={classes.listWrapper}>
        {
          !isEmpty(menu) ?
            getSortedMenuKeys(menu).map(categoryId => (
              <div
                id={`${prefix}${categoryId}${suffix}`}
                key={`${prefix}${categoryId}${suffix}`}
                className={classes.categoryBox}
                data-category={categoryId}
              >
                <Typography className={classes.categoryTitle}>
                  {get(menu[categoryId], '0.merchandise.store_category.name', '')}
                </Typography>
                {
                  menu[categoryId].map(item => (
                    <div
                      key={get(item, 'merchandise.id', -1)}
                      className={classes.cartListItem}
                    >
                      <MenuCard
                        onAdd={onAdd}
                        onClick={onShowInfo}
                        title={get(item, 'merchandise.name', '')}
                        desc={get(item, 'merchandise.description', '')}
                        price={get(item, 'merchandise.price', '')}
                        discount={findTargetPromotion(get(item, 'merchandise.id', -1), promotion, new Date())}
                        pic={get(item, 'merchandise.images[0]', '')}
                        item={get(item, 'merchandise', {})}
                        more={hasSecondaryMenu(get(item, 'merchandise', {}))}
                      />
                    </div>
                  ))
                }
              </div>
            ))
            :
            <Typography
              className={classes.placeholder}
              variant="subtitle1"
            >
              tom.
            </Typography>
        }
      </div>
    );
  }
}

MerchandiseList.propTypes = {
  classes: PropTypes.shape().isRequired,
  menu: PropTypes.PropTypes.shape().isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onAdd: PropTypes.func,
  onShowInfo: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

MerchandiseList.defaultProps = {
  onAdd: () => null,
  onShowInfo: () => null,
  prefix: '',
  suffix: '',
};

export default withStyles(styles)(MerchandiseList);
