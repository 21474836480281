import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SignForm from './Signin';

import styles from './styles';

class SigninModal extends React.PureComponent {
  handleClose = () => {
    this.props.onClose();
  }

  render() {
    const { open, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={() => null}
      >
        <DialogTitle>
          <Typography>Signin</Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <SignForm
            onFinish={this.props.onClose}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="primary"
          >
            Luk
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SigninModal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

SigninModal.defaultProps = {
  open: false,
  onClose: () => null,
};

export default withStyles(styles)(SigninModal);
