import React from 'react';
import qs from 'qs';
import moment from 'moment';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import CategoryDrawer from 'components/presentations/CategoryDrawer';
import ExtrasModal from 'components/presentations/ExtrasModal';
import OfflineModal from 'components/presentations/OfflineModal';
import PurchaseDrawer from 'components/presentations/PurchaseDrawer';
import CategorySidebar from 'components/presentations/CategorySidebar';
import PurchaseSidebar from 'components/presentations/PurchaseSidebar';
import RestaurantInfoBar from 'components/presentations/RestaurantInfoBar';
import MenuCard from 'components/presentations/MenuCard';
// import ScrollToTarget from 'components/presentations/ScrollToTarget';
import CartBottomBar from 'components/presentations/CartBottomBar';
import Loadmore from 'components/presentations/Loadmore';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { replace, push } from 'react-router-redux';
import { replace, push } from 'connected-react-router';
import { fetchCategory } from 'status/partial/category';
import { fetchMenu, getDefaultMenuItem, findCarrierBag } from 'status/partial/menu';
import { fetchPromotion } from 'status/partial/promotion';
import { findTargetPromotion } from 'utils/promotionTools';
import {
  addDish,
  addMultipleDish,
  removeDish,
  mapItemToCart,
  isCarrierBagInCart,
} from 'status/partial/cart';
import { isEmpty, get, isNumber } from 'lodash';
import { message, notification } from 'antd';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import {
  filterMenu,
  filterCategory,
  hasSecondaryMenu,
  isEnableTheCarrierBagFeature,
  initCarrierBagInCart,
} from 'views/index/templates/utils/menutools';

import styles from './styles';

const DEFAULT_STACK_SIZE = 20;

class Index extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedCateforyId !== nextProps.selectedCateforyId) {
      return {
        isSidebarOpen: false,
        selectedCateforyId: nextProps.selectedCateforyId,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: false,
      isExtrasModalOpen: false,
      isPurchaseOpen: false,
      currentMerchandise: getDefaultMenuItem(),
      stack: DEFAULT_STACK_SIZE,
      stepSize: 10,
      selectedCateforyId: -1,
    };
  }

  componentDidMount() {
    const nowUTC = moment().toISOString();
    this.props.fetchCategory({ store: this.props.store.id });
    this.props.fetchMenu({ store: this.props.store.id });
    this.props.fetchPromotion({
      store: this.props.store.id,
      start_time: nowUTC,
      end_time: nowUTC,
    });
  }

  componentDidUpdate() {
    const { menu, configuration, dispatch, cart } = this.props;
    const carrierBagSPU = findCarrierBag(menu);
    if (
      isEnableTheCarrierBagFeature(
        carrierBagSPU,
        get(configuration, 'carrierbags_in_force', false),
      )
      &&
      !isCarrierBagInCart(cart)
    ) {
      initCarrierBagInCart(carrierBagSPU, dispatch);
    }
  }

  onAdd = (item) => {
    // secondary menu
    if (hasSecondaryMenu(item)) {
      this.setState({ isExtrasModalOpen: true, currentMerchandise: item });
      return;
    }

    // without secondary menu
    this.addDishToCart(mapItemToCart(item.merchandisespec_set[0], item.store_category));
  }

  onLoadmode = (menu) => {
    if (this.state.stack < menu.length) {
      this.setState({
        stack: this.state.stack + this.state.stepSize,
      });
    } else {
      this.setState({
        stack: menu.length,
      });
    }
  }

  addDishToCart = (dish, count) => {
    if (isNumber(count)) {
      this.props.addMultipleDish({
        dish,
        count,
      });
    } else {
      this.props.addDish(dish);
    }
    notification.success({
      message: 'Varen er nu tilføjet til kurven!',
      duration: 2,
    });
  }

  tagChange = (tag = -1) => {
    this.setState({
      stack: DEFAULT_STACK_SIZE,
    });
    this.props.dispatch(replace({
      search: `?tag=${tag}`,
    }));
  }

  next = () => {
    const { cart, auth } = this.props;
    if (cart.length === 0) {
      message.warning('Indkøbskurven er tom.');
      return;
    }
    if (isEmpty(auth)) {
      this.props.dispatch(push({
        pathname: '/signin',
        search: '?to=/shipping',
      }));
      return;
    }
    this.props.dispatch(push({
      pathname: '/shipping',
    }));
  }

  render() {
    const {
      isSidebarOpen,
      isExtrasModalOpen,
      isPurchaseOpen,
      currentMerchandise,
      stack,
    } = this.state;
    const {
      classes,
      selectedCateforyId,
      store,
      cart,
      promotion,
      configuration,
    } = this.props;
    const menu = filterMenu(this.props.menu, selectedCateforyId);
    const category = filterCategory(this.props.menu, this.props.category);

    if (isEmpty(this.props.menu)) {
      return (
        <div
          className="loadingio-eclipse"
        >
          <div className="ldio-rpinwye8j0b">
            <div />
          </div>
        </div>
      );
    }

    return (
      <main className={classes.wrapper}>
        <Topbar
          onMenuClick={() => this.setState({ isSidebarOpen: true })}
          onCartClick={() => this.setState({ isPurchaseOpen: true })}
          position="static"
          cartIcon
          signin
          menu
        />
        <div
          className={classes.contentWrapper}
          ref={(el) => {
            this.contentWrapperNode = el;
            return el;
          }}
        >
          <RestaurantInfoBar
            name={store.name}
            address={`${store.street_name} ${store.house_number} ${store.floor_number}, ${store.post_number} ${store.city_name}`}
            cellphone={store.main_phone_number}
          />
          <div className={classes.content}>
            <aside className={classes.asideLeft}>
              <CategorySidebar
                listProps={{
                  category,
                  onChange: this.tagChange,
                  selectedId: selectedCateforyId,
                }}
              />
            </aside>
            <div className={classes.cardWrapper}>
              <div className={classes.deliveryInfo}>
                {
                  !isEmpty(store.nonoco_tips) &&
                    <Typography className={classes.nonocoTips} variant="h6" align="center">
                      { store.nonoco_tips }
                    </Typography>
                }
                <ListItem className={classes.deliveryHead}>
                  <ListItemIcon>
                    <RestaurantMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary="Menukort" />
                </ListItem>
              </div>
              {
                menu.length > 0 ?
                  menu.slice(0, stack).map(item => (
                    <MenuCard
                      key={`menu_${item.id}`}
                      onAdd={this.onAdd}
                      title={item.name}
                      desc={item.description}
                      price={item.price}
                      discount={findTargetPromotion(item.id, promotion, new Date())}
                      pic={item.images[0]}
                      item={item}
                      more={hasSecondaryMenu(item)}
                    />
                  ))
                  :
                  <Typography
                    className={classes.placeholder}
                    variant="subtitle1"
                  >
                    tom.
                  </Typography>
              }
            </div>
            <div className={classes.asideRight}>
              <PurchaseSidebar
                onSubmit={this.next}
                deliveryAllow={store.is_delivery}
              />
            </div>
          </div>
          <Footer />
        </div>
        <CategoryDrawer
          open={isSidebarOpen}
          onClose={() => this.setState({ isSidebarOpen: false })}
          listProps={{
            category,
            onChange: tag => this.tagChange(tag),
            selectedId: selectedCateforyId,
          }}
        />
        <CartBottomBar
          onClick={() => this.setState({ isPurchaseOpen: true })}
          cart={cart}
          promotion={promotion}
        />
        <PurchaseDrawer
          open={isPurchaseOpen}
          onClose={() => this.setState({ isPurchaseOpen: false })}
          onSubmit={this.next}
          deliveryAllow={store.is_delivery}
        />
        <ExtrasModal
          open={isExtrasModalOpen}
          onClose={() => this.setState({ isExtrasModalOpen: false })}
          add={this.addDishToCart}
          cart={cart}
          merchandise={currentMerchandise}
          promotion={promotion}
          title={currentMerchandise.name}
          category={get(this.props, 'category', [])}
        />
        {/*
        <ScrollToTarget
          onClick={() => {
            document.body.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        />
        */}
        <OfflineModal
          open={get(configuration, 'status', 'open') === 'close'}
          onClose={() => {
            this.setState({
              isOfflineOpen: false,
            });
          }}
          email={store.main_email}
          phone={store.main_phone_number}
        />
        {
          !isEmpty(this.contentWrapperNode) &&
            <Loadmore
              targetNode={this.contentWrapperNode}
              onLoad={() => this.onLoadmode(menu)}
              offset={600}
            />
        }
      </main>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  fetchCategory: PropTypes.func.isRequired,
  fetchMenu: PropTypes.func.isRequired,
  fetchPromotion: PropTypes.func.isRequired,
  addDish: PropTypes.func.isRequired,
  addMultipleDish: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  category: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  store: PropTypes.shape().isRequired,
  selectedCateforyId: PropTypes.number.isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  menu: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  configuration: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default connect(state => ({
  auth: state.auth,
  category: state.category,
  selectedCateforyId: parseInt(qs.parse(state.router.location.search.slice(1)).tag, 10) || -1,
  store: state.store,
  cart: state.cart,
  promotion: state.promotion,
  menu: state.menu,
  configuration: state.configuration,
}), dispatch => ({
  fetchCategory: bindActionCreators(fetchCategory, dispatch),
  fetchMenu: bindActionCreators(fetchMenu, dispatch),
  fetchPromotion: bindActionCreators(fetchPromotion, dispatch),
  addDish: bindActionCreators(addDish, dispatch),
  addMultipleDish: bindActionCreators(addMultipleDish, dispatch),
  removeDish: bindActionCreators(removeDish, dispatch),
  dispatch,
}))(withStyles(styles)(Index));
