import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CategoryList from 'views/index/templates/vanilla/components/CategoryList';
import styles from './styles.js';


class CategoryTopBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.scrollWrap = React.createRef();
    const offset = Math.floor(window.document.body.scrollWidth * 0.618);
    this._OFFSET = offset < 72 ? 72 : offset;
    this._OFFSET_STEP = 15;
    this._OFFSET_STEP_LIMIT = 10;
  }

  onForwardArrowClick = () => {
    let restOffset = this._OFFSET;
    const timer = setInterval(() => {
      const stepRange = Math.floor(restOffset / this._OFFSET_STEP);
      restOffset -= stepRange;
      if (restOffset <= this._OFFSET_STEP_LIMIT) {
        this.scrollWrap.current.scrollLeft += stepRange;
        clearInterval(timer);
      } else {
        this.scrollWrap.current.scrollLeft += stepRange;
      }
    }, 20);
  }

  onBackArrowClick = () => {
    let restOffset = this._OFFSET;
    const timer = setInterval(() => {
      const stepRange = Math.floor(restOffset / this._OFFSET_STEP);
      restOffset -= stepRange;
      if (restOffset <= this._OFFSET_STEP_LIMIT) {
        this.scrollWrap.current.scrollLeft -= stepRange;
        clearInterval(timer);
      } else {
        this.scrollWrap.current.scrollLeft -= stepRange;
      }
    }, 20);
  }

  render() {
    const props = this.props;
    return (
      <div
        className={props.classes.wrapper}
      >
        <ArrowBackIosIcon
          className={props.classes.arrowBack}
          onClick={this.onBackArrowClick}
        />
        <div
          className={props.classes.scroll}
          onScroll={props.onScroll}
          ref={this.scrollWrap}
        >
          <CategoryList {...props.listProps} />
        </div>
        <ArrowForwardIosIcon
          className={props.classes.arrowForward}
          onClick={this.onForwardArrowClick}
        />
      </div>
    );
  }
}

CategoryTopBar.propTypes = {
  classes: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
  onScroll: PropTypes.func,
  listProps: PropTypes.shape({
    onChange: PropTypes.func,
    category: PropTypes.arrayOf(PropTypes.shape()),
    rootEl: PropTypes.string,
    prefix: PropTypes.string,
    scrollSpyNameList: PropTypes.arrayOf(PropTypes.string),
  }),
};

CategoryTopBar.defaultProps = {
  onChange: () => null,
  onScroll: e => e.stopPropagation,
  listProps: {
    onChange: () => null,
    category: [],
    rootEl: 'body',
    prefix: '',
    scrollSpyNameList: [],
  },
};

export default withStyles(styles)(CategoryTopBar);
