import { curry } from 'lodash';
import { screens } from './variables';

export const breakPoint = curry((size, content) => {
  const key = `@media (min-width: ${size}px)`;
  return {
    [key]: {
      ...content,
    },
  };
});

export const mediaMedium = breakPoint(screens.md);
export const mediaLarge = breakPoint(screens.lg);
export const mediaXlarge = breakPoint(screens.xl);
export const mediaXXlarge = breakPoint(screens.xxl);

export const controlOverflow = function controlOverflow() {
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };
};

export const wrapper = function wrapper() {
  return {
    minHeight: '100vh',
  };
};

export const scrollbar = function scrollbar(theme) {
  return {
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 30,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
    },
  };
};

export const lineClamp = function lineClamp(clamp = 3) {
  return {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: clamp,
    overflow: 'hidden',
  };
};

/*
@mixin line-limit($line: 2, $line-height: 20px) {
  line-height: $line-height;
  height: calc(#{$line} * #{$line-height});
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
*/
