import amber from '@material-ui/core/colors/amber';
import { mediaLarge } from 'themes/mixins';

export const navigatorListStyle = theme => ({
  active: {
    '& h3 span': {
      color: amber[600],
    },
  },
  item: {
    display: 'block',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

export const navigatorSidebarStyle = theme => ({
  wrapper: {
    display: 'none',
  },
  ...mediaLarge({
    wrapper: {
      display: 'block',
      boxShadow: theme.shadows[1],
    },
  }),
});

export const navigatorDrawerStyle = theme => ({
  head: theme.mixins.toolbar,
  ...mediaLarge({
    wrapper: {
      display: 'none',
    },
  }),
});

export default theme => ({
  head: theme.mixins.toolbar,
  active: {
    '& h3 span': {
      color: amber[600],
    },
  },
  item: {
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
});
