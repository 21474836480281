export default theme => ({
  head: theme.mixins.toolbar,
  active: {
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  item: {
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
});
