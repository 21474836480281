import { colors } from 'themes/variables';
import { mediaLarge } from 'themes/mixins';

const baseStyle = theme => ({
  cartButton: {
    marginRight: -12,
    color: theme.palette.primary.main,
  },
  cartIcon: {
    fontSize: '150%',
  },
  badge: {
    color: colors.focus,
    transform: 'scale(1.25)',
    top: '-1.5rem',
  },
});

export default theme => ({
  wrapper: {
    display: 'none',
  },
  ...mediaLarge({
    wrapper: {
      display: 'block',
      fontSize: '250%',
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    ...baseStyle(theme),
  }),
});
