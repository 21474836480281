import { mediaLarge, scrollbar } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'none',
  },
  content: {
    maxWidth: '610px',
    ...scrollbar(theme),
  },
  ...mediaLarge({
    wrapper: {
      display: 'block',
      // boxShadow: theme.shadows[1],
      position: 'sticky',
      top: theme.spacing(),
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      overflowY: 'scroll',
      ...scrollbar(theme),
    },
  }),
});
