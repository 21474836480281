import { setSessionStorage } from 'utils/sessionStorage';
import { actionTypes as nonocoTipsActionTypes, SESSTION_KEY } from 'status/partial/ui/showtips';

const saveNonocoTipsStateToSesstion = () => next => (action) => {
  const result = next(action);
  switch (action.type) {
    case nonocoTipsActionTypes.CLOSE_NONOCO_TIPS:
      setSessionStorage(SESSTION_KEY, 1);
      break;
    default:
      break;
  }
  return result;
};

export default saveNonocoTipsStateToSesstion;
