import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import OrderDetailModal from 'components/presentations/OrderDetailModal';
import PropTypes from 'prop-types';
import qs from 'qs';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { setOrderinfo } from 'status/partial/orderinfo';
// import { replace, push } from 'react-router-redux';
import { replace, push } from 'connected-react-router';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import classNames from 'classnames';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import CouponIcon from '@material-ui/icons/LocalAtm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import GiftCardIcon from '@material-ui/icons/CardGiftCard';
import PlaceIcon from '@material-ui/icons/LocationOn';
import CheckCircleIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { message } from 'antd';
import { isEmpty, first, find, get } from 'lodash';
import { newOrder, paidCallbackUrl } from 'apis/services/order';
import { getScanPayLink, getFreePayLink } from 'apis/services/payment';
import { inactiveCoupon, getCouponItem, createNewCouponKey } from 'apis/services/coupon';
import { Link } from 'react-router-dom';
import {
  // getTotalCartItemAmount,
  // totalAmountOfCart,
  // getTotalCartItemDiscount,
  totalDiscountOfCart,
  getUnitCartItemDiscount,
  getUnitCartItemAmount,
  getAmountWithCoupon,
} from 'utils/getCartAmount';
import formatCurrency from 'utils/formatCurrency';
import isShowCartItemDetail from 'utils/isShowCartItemDetail';
import { isTargetDatetimeInRange, isDatetimeInWeeklySchedule } from 'utils/timeTools';
import { simplifyTargetCartItem, getDefaultCartItem } from 'status/partial/cart';
import { fetchCoupon } from 'status/partial/coupon';
import { fetchCouponPromotion } from 'status/partial/couponpromotion';
import urls from 'apis/configure/urls';
import styles from './styles';

const { apiUrlV1 } = urls;

function mapCartToSkuList(cart) {
  return cart.map(item => ({
    sku_id: item.sku.id,
    count: item.count,
    extras: item.extras.map(ext => ({
      extra_id: ext.extra.id,
      count: ext.count,
    })),
    parts: item.parts.map(part => ({
      part_id: part.part.id,
      count: part.count,
    })),
    store_category_id: item.storeCategory.id,
    attach_properties: item.attachProperties.map(prop => ({
      key: prop.key,
      value: prop.value,
    })),
  }));
}

function getCartItemKey(cartItem) {
  const target = simplifyTargetCartItem(cartItem);
  return JSON.stringify(target);
}

function getDistanceMatrix(service, params) {
  return new Promise((resolve, reject) => {
    service.getDistanceMatrix(params, (response, status) => {
      if (status !== 'OK') {
        reject(status);
      } else {
        resolve(response);
      }
    });
  });
}

function getDeliveryWayText(value, time) {
  if (value === 'pick_up') {
    return (
      <React.Fragment>
        <ListItemIcon><PlaceIcon /></ListItemIcon>
        <ListItemText><Typography>Afhentning</Typography></ListItemText>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
      <ListItemText><Typography>Udbringning { time }</Typography></ListItemText>
    </React.Fragment>
  );
}

function distanceBeyond() {
  message.error('uden levering afstand');
}

function renderCouponContent(coupon) {
  switch (coupon.coupon_promotion.promotion_type) {
    case 'discount':
      return `${coupon.coupon_promotion.detail.value * 100}% of Your Next Purchase`;
    case 'set_price':
      return `${coupon.coupon_promotion.detail.value} Only Your Next Purchase`;
    case 'reduce_price':
      return `${coupon.coupon_promotion.detail.value} off`;
    case 'per_reduce_price':
      return `${coupon.coupon_promotion.detail.value} off for Every ${coupon.coupon_promotion.effective_amount}`;
    default:
      return '';
  }
}

function renderCouponType(coupon) {
  switch (coupon.coupon_promotion.promotion_type) {
    case 'discount':
      return 'Discount';
    case 'set_price':
      return 'Price';
    case 'reduce_price':
      return 'Reduce Price';
    case 'per_reduce_price':
      return 'Discount per full purchase';
    default:
      return '';
  }
}

function couponFilter(item, orderAmount, now, store) {
  const couponPromotion = get(item, 'coupon_promotion', {});
  if (couponPromotion.effective_amount > orderAmount) {
    return false;
  }
  if (!isTargetDatetimeInRange(
    now,
    new Date(couponPromotion.start_time),
    new Date(couponPromotion.end_time))
  ) {
    return false;
  }
  if (!isDatetimeInWeeklySchedule(
    now,
    couponPromotion.active_weekly_schedule,
  )) {
    return false;
  }

  if (['all', 'net'].indexOf(couponPromotion.platform) < 0) {
    return false;
  }

  if (couponPromotion.store.id !== store.id) {
    return false;
  }
  return true;
}

function getFirstAutoDistributeCouponPromotion(promotionList, orderAmount, now, store) {
  const firstFilteredPromotion = promotionList.filter(
    item => couponFilter({ coupon_promotion: item }, orderAmount, now, store),
  );
  const secondFilteredPromotion = firstFilteredPromotion.filter(
    item => get(item, 'configuration.distribution_type', 'manual') === 'auto',
  );
  return secondFilteredPromotion[0];
}

class Purchase extends React.PureComponent {
  state = {
    agree: false,
    canDelivery: true,
    distance: 0,
    isOpenDetailModal: false,
    submitting: false,
    currentDish: getDefaultCartItem(),
    selectedCoupon: {},
    inputCouponKey: '',
    parentWindowLocation: {},
  }

  componentDidMount() {
    const { orderinfo, auth, store } = this.props;
    if (orderinfo.order_type === 'delivery') {
      this.getDeliveryPrice();
    }
    const email = get(auth, 'user.email', '');
    if (!isEmpty(email)) {
      this.props.fetchCoupon({
        contact_email: email,
      });
    }
    const nowUTC = moment().toISOString();
    this.props.fetchCouponPromotion({
      store: store.id,
      start_time: nowUTC,
      end_time: nowUTC,
    });
    this.handleParentWindowMessage();

    if (parent != null) {
      parent.postMessage(JSON.stringify({
        type: 'get_parent_location',
        payload: {},
      }), '*');
    }
  }

  getDeliveryPrice = async () => {
    /* global google:true */
    message.loading();
    const { orderinfo, store, delivery } = this.props;
    const origin = `${store.street_name} ${store.house_number}, ${store.post_number} ${store.city_name}`;
    const destination = `${orderinfo.street_name} ${orderinfo.house_number} ${orderinfo.post_number}`;
    const service = new google.maps.DistanceMatrixService();
    const response = await getDistanceMatrix(service, {
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    });
    const info = response.rows[0].elements[0];
    let results = 0;
    if (info.status === 'OK') {
      results = info.distance.value / 1000;
    } else {
      this.setState({
        canDelivery: false,
      });
      distanceBeyond();
      return;
    }
    message.success(results);
    message.destroy();

    const targetArea = find(
      delivery.deliveryarea_set,
      area => area.post_number === orderinfo.post_number,
    );
    if (targetArea == null) {
      distanceBeyond();
      return;
    }

    const radius = targetArea.deliveryradius_set.sort((x, y) => x.radius - y.radius);
    const targetRadius = radius.filter(item => item.radius >= results);
    if (targetRadius === 0) {
      distanceBeyond();
      return;
    }
    const targetRadiusPrice = first(targetRadius);
    this.props.setOrderinfo('delivery_amount', targetRadiusPrice.amount);
    this.setState({
      distance: results,
    });
  }

  getPaymentWindow = (orderCode, paymentType = '') => {
    /* global PaymentWindow:true */
    const { store, cart, orderinfo, promotion } = this.props;
    const { selectedCoupon, parentWindowLocation } = this.state;
    let merchantNumber = '';
    if (process.env.NODE_ENV === 'development') {
      merchantNumber = get(store, 'merchant_number', '8030657');
      // merchantNumber = '8040376'; // bluefin
    }
    if (process.env.NODE_ENV === 'production') {
      merchantNumber = store.merchant_number;
    }
    const discountedPrice = totalDiscountOfCart(
      cart,
      promotion,
      new Date(),
    );
    let finalAmount = getAmountWithCoupon(
      discountedPrice,
      selectedCoupon,
    ) + orderinfo.delivery_amount;
    if (paymentType === 'card') {
      finalAmount += 1.89; // the 1.89 is card fees
    }
    let accepturl = `${window.location.href.replace('#/purchase', '#/done')}`;
    if (parent != null) {
      accepturl = `${parentWindowLocation.href}?order_code=${orderCode}&epay_success=1`;
    }
    let paymentWindowParams = {
      accepturl,
      merchantnumber: merchantNumber,
      amount: Math.round(finalAmount * 100),
      currency: 'DKK',
      windowstate: '3',
      orderid: orderCode,
      paymentcollection: '1',
      instantcapture: '1',
      instantcallback: '1',
      // callbackurl: paidCallbackUrl,
    };

    // If the merchant number belone nic-group then attach the callback url.
    // The 8030657 for test, 1030657 for product.
    if (merchantNumber === '8030657' || merchantNumber === '1030657') {
      paymentWindowParams = {
        ...paymentWindowParams,
        ...{
          callbackurl: paidCallbackUrl,
        },
      };
    }
    const paymentwindow = new PaymentWindow(paymentWindowParams);
    return paymentwindow;
  }

  handleParentWindowMessage = () => {
    window.addEventListener('message', (e) => {
      const msg = JSON.parse(e.data);
      // console.log(msg);
      switch (msg.type) {
        case 'set_parent_location':
          this.setState({
            parentWindowLocation: msg.payload,
          });
          break;
        default:
          break;
      }
    });
  }

  openDetailModal = (cartItem) => {
    if (isShowCartItemDetail(cartItem)) {
      this.setState({
        currentDish: cartItem,
        isOpenDetailModal: true,
      });
    }
  }

  inactiveCoupon = () => {
    const { selectedCoupon } = this.state;
    if (!isEmpty(selectedCoupon) && !get(selectedCoupon, 'is_reused', false)) {
      inactiveCoupon(selectedCoupon.id);
    }
  }

  distributionCoupon = () => {
    /*
    const { orderinfo } = this.props;
    const { selectedCoupon } = this.state;
    const distributionType = get(
      selectedCoupon,
      'coupon_promotion.configuration.distribution_type',
      '',
    );
    if (distributionType === 'auto') {
      createNewCouponKey({
        coupon_promotion_id: get(selectedCoupon, 'coupon_promotion.id', 0),
        contact_email: orderinfo.contact_email,
      });
    }
    */
    const { couponPromotion, orderinfo, store, cart, promotion } = this.props;
    const { selectedCoupon } = this.state;
    const now = new Date();
    const discountedPrice = totalDiscountOfCart(cart, promotion, new Date());
    const amountWithCoupon = getAmountWithCoupon(discountedPrice, selectedCoupon);
    const target = getFirstAutoDistributeCouponPromotion(
      couponPromotion,
      amountWithCoupon,
      now,
      store,
    );
    if (!isEmpty(target)) {
      createNewCouponKey({
        coupon_promotion_id: get(target, 'id', 0),
        contact_email: orderinfo.contact_email,
      });
    }
  }

  payInCash = async () => {
    try {
      this.setState({
        submitting: true,
      });
      message.loading('', 30);
      const newOrderResponse = await newOrder(this.composeOrder('cash'));
      this.inactiveCoupon();
      this.distributionCoupon();
      message.destroy();
      this.setState({
        submitting: false,
      });
      this.props.setOrderinfo('order_code', newOrderResponse.data.order_code);
      this.props.dispatch(push({
        pathname: '/done',
      }));
    } catch (error) {
      message.destroy();
      this.setState({
        submitting: false,
      });
      message.error('Betaling mislykkedes');
    }
  }

  agree = (value) => {
    this.setState({
      agree: value,
    });
  }

  composeOrder = (paymentType) => {
    const { cart, orderinfo, store, diner } = this.props;
    const { selectedCoupon } = this.state;
    const dinerInfo = isEmpty(diner) ? {} : { diner_id: diner.id };
    const couponInfo = isEmpty(selectedCoupon) ? {} : { coupon_id: selectedCoupon.id };
    const params = JSON.parse(JSON.stringify({
      sku_list: [
        ...mapCartToSkuList(cart),
      ],
      ...orderinfo,
      ...dinerInfo,
      ...couponInfo,
      payment_type: paymentType,
      store_id: store.id,
    }));
    return params;
  }

  payInCard = async () => {
    const { router, orderinfo } = this.props;
    const orderId = qs.parse(router.location.search.slice(1)).id;
    if (orderId != null) {
      const paymentwindow = this.getPaymentWindow(orderinfo.order_code, 'card');
      paymentwindow.open();
      return;
    }

    try {
      this.setState({
        submitting: true,
      });
      message.loading('', 30);
      const newOrderResponse = await newOrder(this.composeOrder('card'));
      this.inactiveCoupon();
      this.distributionCoupon();
      this.setState({
        submitting: false,
      });
      message.destroy();
      const orderNumber = newOrderResponse.data.order_code;
      this.props.setOrderinfo('order_code', orderNumber);
      this.props.dispatch(replace({
        search: `?id=${newOrderResponse.data.id}`,
      }));
      const paymentwindow = this.getPaymentWindow(orderNumber, 'card');
      paymentwindow.open();
    } catch (error) {
      this.setState({
        submitting: false,
      });
      message.destroy();
      message.error(JSON.stringify(error.response.data));
    }
  }

  payInScanPay = async () => {
    const { cart, orderinfo, promotion, store } = this.props;
    const { selectedCoupon } = this.state;
    const discountedPrice = totalDiscountOfCart(
      cart,
      promotion,
      new Date(),
    );
    let finalAmount = getAmountWithCoupon(
      discountedPrice,
      selectedCoupon,
    ) + orderinfo.delivery_amount;

    finalAmount += 1.89; // the 1.89 is card fees

    const { router } = this.props;
    let paymentLink = qs.parse(router.location.search.slice(1)).scanpay_link;
    if (paymentLink != null) {
      window.location.href = paymentLink;
      return;
    }

    try {
      this.setState({
        submitting: true,
      });
      message.loading('', 30);
      const newOrderResponse = await newOrder(this.composeOrder('card'));
      const getScanPayLinkRes = await getScanPayLink({
        orderid: newOrderResponse.data.id,
        successurl: `${apiUrlV1}/payment/scanpay_callback/?redirect_to=${encodeURIComponent(`${window.location.origin}/?store=${get(store, 'id', 0)}#/done`)}&order_id=${newOrderResponse.data.id}`,
        items: [{
          name: newOrderResponse.data.order_code,
          total: `${finalAmount.toFixed(2)} DKK`,
        }],
      });
      paymentLink = getScanPayLinkRes.data.url;
      this.inactiveCoupon();
      this.distributionCoupon();
      this.setState({
        submitting: false,
      });
      message.destroy();
      const orderNumber = newOrderResponse.data.order_code;
      this.props.setOrderinfo('order_code', orderNumber);
      this.props.dispatch(replace({
        search: `?scanpay_link=${paymentLink}`,
      }));
      window.location.href = paymentLink;
    } catch (error) {
      this.setState({
        submitting: false,
      });
      message.destroy();
      message.error(JSON.stringify(error.response.data));
    }
  }

  payInFreePay = async () => {
    const { cart, orderinfo, promotion/* , store */ } = this.props;
    const { selectedCoupon, parentWindowLocation } = this.state;
    const discountedPrice = totalDiscountOfCart(
      cart,
      promotion,
      new Date(),
    );
    let finalAmount = getAmountWithCoupon(
      discountedPrice,
      selectedCoupon,
    ) + orderinfo.delivery_amount;

    finalAmount += 1.89; // the 1.89 is card fees

    const { router } = this.props;
    let paymentLink = qs.parse(router.location.search.slice(1)).freepay_link;
    if (paymentLink != null) {
      window.location.href = paymentLink;
      return;
    }

    try {
      this.setState({
        submitting: true,
      });
      message.loading('', 30);
      const newOrderResponse = await newOrder(this.composeOrder('card'));
      const getFreePayLinkRes = await getFreePayLink({
        orderid: newOrderResponse.data.id,
        successurl: `${parentWindowLocation.href}?order_id=${newOrderResponse.data.id}&order_code=${newOrderResponse.data.order_code}&freepay_success=1`,
        redirect_to: encodeURIComponent(`${parentWindowLocation.href}?order_code=${newOrderResponse.data.order_code}&freepay_success=0`),
        amount: Math.round(finalAmount * 100),
      });
      paymentLink = getFreePayLinkRes.data.paymentWindowLink;
      this.inactiveCoupon();
      this.distributionCoupon();

      const orderNumber = newOrderResponse.data.order_code;
      this.props.setOrderinfo('order_code', orderNumber);
      this.props.dispatch(replace({
        search: `?freepay_link=${paymentLink}`,
      }));
      this.setState({
        submitting: false,
      });
      message.destroy();

      // if nonoco in a iframe
      if (parent != null) {
        parent.postMessage(JSON.stringify({
          type: 'redirect_freepay_link',
          payload: {
            url: paymentLink,
          },
        }), '*');
      } else {
        // not in a iframe
        window.location.href = paymentLink;
      }
    } catch (error) {
      this.setState({
        submitting: false,
      });
      message.destroy();
      message.error(JSON.stringify(error.response.data));
    }
  }

  selectCoupon = (coupon) => {
    const { selectedCoupon } = this.state;
    if (selectedCoupon.id === coupon.id) {
      this.setState({
        selectedCoupon: {},
      });
    } else {
      this.setState({
        selectedCoupon: coupon,
      });
    }
  }

  submitCoupon = async () => {
    const { cart, promotion, store } = this.props;
    const discountedPrice = totalDiscountOfCart(
      cart,
      promotion,
      new Date(),
    );
    const msg = 'Kuponen eksistere ikke eller opfylder ikke betingelserne for brug.';
    try {
      this.setState({
        submitting: true,
      });
      const coupon = await getCouponItem(this.state.inputCouponKey);
      if (couponFilter(coupon.data, discountedPrice, new Date(), store)) {
        this.setState({
          selectedCoupon: coupon.data,
          submitting: false,
        });
      } else {
        throw new Error(msg);
      }
    } catch (error) {
      this.setState({
        submitting: false,
      });
      message.destroy();
      message.error(msg);
    }
  }

  renderDeliveryInfo = () => {
    const { orderinfo, classes } = this.props;
    const { canDelivery, distance } = this.state;
    if (orderinfo.order_type === 'delivery') {
      if (canDelivery) {
        return (
          <ListItem className={classes.listItem}>
            <ListItemText>
              <Typography>Lerveringgebyr</Typography>
            </ListItemText>
            <ListItemText className={classes.price}>
              <Typography>
                ({distance}km) {formatCurrency(orderinfo.delivery_amount)} DKK
              </Typography>
            </ListItemText>
          </ListItem>
        );
      }
      return (
        <ListItem className={classes.listItem}>
          <ListItemText>
            <Typography>Lerveringgebyr</Typography>
          </ListItemText>
          <ListItemText className={classNames(classes.price, classes.light)}>
            <Typography className={classes.light}>uden levering afstand</Typography>
          </ListItemText>
        </ListItem>
      );
    }
    return null;
  }

  render() {
    const { classes, cart, store, orderinfo, promotion, coupon, configuration } = this.props;
    const {
      agree,
      canDelivery,
      isOpenDetailModal,
      currentDish,
      submitting,
      selectedCoupon,
      inputCouponKey,
    } = this.state;
    // const totalPrice = totalAmountOfCart(cart);
    const discountedPrice = totalDiscountOfCart(cart, promotion, new Date());
    const availableCoupon = coupon.filter(
      item => couponFilter(item, discountedPrice, new Date(), store),
    );
    const amountWithCoupon = getAmountWithCoupon(discountedPrice, selectedCoupon);
    return (
      <main className={classes.wrapper}>
        <Topbar />
        <Paper className={classes.content}>
          <ListItem className={classes.title}>
            <ListItemIcon><LocalGroceryStoreIcon /></ListItemIcon>
            <ListItemText>Hvordan vil du betale</ListItemText>
          </ListItem>
          <Divider />
          <div className={classes.formWrapper}>
            <Grid container spacing={3} >
              <Grid item xs={12} md={4}>
                <div>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <ListItemIcon><CouponIcon /></ListItemIcon>
                      <ListItemText
                        className={classes.heading}
                      >
                        Aktiveret kupon({availableCoupon.length})
                      </ListItemText>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.couponContainer}>
                      <div>Vis kun aktuel tilgængelighed kupon.</div>
                      {
                        availableCoupon.map(item => (
                          <div
                            className={classes.cardWrap}
                            key={item.id}
                          >
                            <Card
                              className={classes.card}
                              onClick={() => this.selectCoupon(item)}
                            >
                              <CardContent>
                                <Typography gutterBottom component="p">
                                  { item.coupon_promotion.name }
                                </Typography>
                                <Typography
                                  className={classes.couponType}
                                  variant="headline"
                                  align="center"
                                  component="h2"
                                  gutterBottom
                                >
                                  { renderCouponType(item) }
                                </Typography>
                                <Typography component="p" gutterBottom>
                                  { renderCouponContent(item) }
                                </Typography>
                                <Typography component="p">
                                  { `${moment(item.coupon_promotion.start_time).format('YYYY/MM/DD')}` }
                                  { ' - ' }
                                  { `${moment(item.coupon_promotion.end_time).format('YYYY/MM/DD')}` }
                                  <GiftCardIcon className={classes.couponIcon} />
                                </Typography>
                                {
                                  selectedCoupon.id === item.id &&
                                    <CheckCircleIcon className={classes.couponSelectedIcon} />
                                }
                              </CardContent>
                            </Card>
                          </div>
                        ))
                      }
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                      <TextField
                        fullWidth
                        label="Manual indtaste kupon"
                        placeholder=""
                        onChange={e => this.setState({ inputCouponKey: e.target.value })}
                        value={inputCouponKey}
                        disabled={submitting}
                      />
                      {
                        submitting &&
                          <Button color="primary" disabled>
                            <CircularProgress size={16} />
                          </Button>
                      }
                      {
                        !submitting &&
                          <Button color="primary" variant="contained" onClick={this.submitCoupon}>
                            Gem
                          </Button>
                      }
                    </ExpansionPanelActions>
                  </ExpansionPanel>
                  {
                    /* Epay */
                    get(store, 'net_pay_type_currently', 'none') === 'epay' &&
                      <ExpansionPanel defaultExpanded={get(configuration, 'net_pay_only', false)}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <ListItemIcon><CreditCardIcon /></ListItemIcon>
                          <ListItemText className={classes.heading}>
                            Betal med kort (epay)
                          </ListItemText>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.details}>
                          <div className={classes.helper}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={agree}
                                  onChange={() => this.agree(!agree)}
                                />
                              }
                              label="Jeg har læst og accepteret handelsvilkår og bekræfter, at jeg er mindst 18/16 år, Hvis ordren indeholder alkoholholdige drikkevarer"
                            />
                            <Typography variant="caption" align="center">
                              {
                                isEmpty(store.epay_term_href) ?
                                  <Link to={{ pathname: '/term' }} className={classes.link}>Købsbetingelser</Link>
                                  :
                                  <a href={store.epay_term_href} target="_blank" rel="noopener noreferrer" className={classes.link}>Købsbetingelser</a>
                              }
                            </Typography>
                          </div>
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                          {
                            (agree && canDelivery && !submitting) &&
                              <Button color="primary" variant="contained" onClick={this.payInCard}>
                                Gennemfør betaling
                              </Button>
                          }
                          {
                            (agree && canDelivery && submitting) &&
                              <Button color="primary" disabled>
                                <CircularProgress size={16} />
                              </Button>
                          }
                          {
                            (!agree && canDelivery) &&
                              <Button
                                color="default"
                                onClick={() => message.warning('Læs venligst og accepterer handelsvilkår for brug')}
                              >
                                Gennemfør betaling
                              </Button>
                          }
                          {
                            (!agree && !canDelivery) &&
                              <Button
                                color="default"
                                onClick={() => message.warning('Læs venligst og accepterer handelsvilkår for brug')}
                              >
                                Gennemfør betaling
                              </Button>
                          }
                          {
                            (agree && !canDelivery) &&
                              <Button color="default" onClick={distanceBeyond}>
                                Gennemfør betaling
                              </Button>
                          }
                        </ExpansionPanelActions>
                      </ExpansionPanel>
                  }
                  {
                    /* Scanpay */
                    get(store, 'net_pay_type_currently', 'none') === 'scanpay' &&
                    <ExpansionPanel defaultExpanded={get(configuration, 'net_pay_only', false)}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <ListItemIcon><CreditCardIcon /></ListItemIcon>
                        <ListItemText className={classes.heading}>
                          Betal med kort
                        </ListItemText>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div className={classes.helper}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={agree}
                                onChange={() => this.agree(!agree)}
                              />
                            }
                            label="Jeg har læst og accepteret handelsvilkår og bekræfter, at jeg er mindst 18/16 år, Hvis ordren indeholder alkoholholdige drikkevarer"
                          />
                          <Typography variant="caption" align="center">
                            {
                              isEmpty(store.epay_term_href) ?
                                <Link to={{ pathname: '/term' }} className={classes.link}>Købsbetingelser</Link>
                                :
                                <a href={store.epay_term_href} target="_blank" rel="noopener noreferrer" className={classes.link}>Købsbetingelser</a>
                            }
                          </Typography>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                      <ExpansionPanelActions>
                        {
                          (agree && canDelivery && !submitting) &&
                            <Button color="primary" variant="contained" onClick={this.payInScanPay}>
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (agree && canDelivery && submitting) &&
                            <Button color="primary" disabled>
                              <CircularProgress size={16} />
                            </Button>
                        }
                        {
                          (!agree && canDelivery) &&
                            <Button
                              color="default"
                              onClick={() => message.warning('Læs venligst og accepterer handelsvilkår for brug')}
                            >
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (!agree && !canDelivery) &&
                            <Button
                              color="default"
                              onClick={() => message.warning('Læs venligst og accepterer handelsvilkår for brug')}
                            >
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (agree && !canDelivery) &&
                            <Button color="default" onClick={distanceBeyond}>
                              Gennemfør betaling
                            </Button>
                        }
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  }
                  {
                    /* Freepay */
                    get(store, 'net_pay_type_currently', 'none') === 'freepay' &&
                    <ExpansionPanel defaultExpanded={get(configuration, 'net_pay_only', false)}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <ListItemIcon><CreditCardIcon /></ListItemIcon>
                        <ListItemText className={classes.heading}>
                          Betal med kort
                        </ListItemText>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div className={classes.helper}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={agree}
                                onChange={() => this.agree(!agree)}
                              />
                            }
                            label="Jeg har læst og accepteret handelsvilkår og bekræfter, at jeg er mindst 18/16 år, Hvis ordren indeholder alkoholholdige drikkevarer"
                          />
                          <Typography variant="caption" align="center">
                            {
                              isEmpty(store.epay_term_href) ?
                                <Link to={{ pathname: '/term' }} className={classes.link}>Købsbetingelser</Link>
                                :
                                <a href={store.epay_term_href} target="_blank" rel="noopener noreferrer" className={classes.link}>Købsbetingelser</a>
                            }
                          </Typography>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                      <ExpansionPanelActions>
                        {
                          (agree && canDelivery && !submitting) &&
                            <Button color="primary" variant="contained" onClick={this.payInFreePay}>
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (agree && canDelivery && submitting) &&
                            <Button color="primary" disabled>
                              <CircularProgress size={16} />
                            </Button>
                        }
                        {
                          (!agree && canDelivery) &&
                            <Button
                              color="default"
                              onClick={() => message.warning('Læs venligst og accepterer handelsvilkår for brug')}
                            >
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (!agree && !canDelivery) &&
                            <Button
                              color="default"
                              onClick={() => message.warning('Læs venligst og accepterer handelsvilkår for brug')}
                            >
                              Gennemfør betaling
                            </Button>
                        }
                        {
                          (agree && !canDelivery) &&
                            <Button color="default" onClick={distanceBeyond}>
                              Gennemfør betaling
                            </Button>
                        }
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  }
                  {
                    /* Pay in cash */
                    !get(configuration, 'net_pay_only', false) &&
                      <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                          <ListItemText className={classes.heading}>
                            Betal ved afhentning
                          </ListItemText>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.details}>
                          <span />
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                          {
                            (canDelivery && !submitting) &&
                              <Button color="primary" variant="contained" onClick={this.payInCash}>
                                Godkend ordre
                              </Button>
                          }
                          {
                            (!canDelivery && !submitting) &&
                              <Button color="default" variant="contained" onClick={distanceBeyond}>
                                Godkend ordre
                              </Button>
                          }
                          {
                            (canDelivery && submitting) &&
                              <Button color="primary" disabled>
                                <CircularProgress size={16} />
                              </Button>
                          }
                        </ExpansionPanelActions>
                      </ExpansionPanel>
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} className={classes.orderTitle}>
                          <Typography variant="h6" align="center" className={classes.orderTitle}>
                            Din ordre
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        cart.map(item => (
                          <TableRow key={getCartItemKey(item)}>
                            <TableCell
                              className={classNames({
                                [classes.title]: true,
                                [classes.more]: isShowCartItemDetail(item),
                              })}
                              onClick={() => this.openDetailModal(item)}
                            >
                              {item.sku.name}
                            </TableCell>
                            <TableCell className={classes.price} align="right">
                              {
                                getUnitCartItemDiscount(item, promotion, new Date()) !==
                                getUnitCartItemAmount(item) &&
                                  <small className={classes.originPrice}>
                                    <del>DKK {formatCurrency(getUnitCartItemAmount(item))}</del>
                                    {' X '} <span className={classes.light}>{item.count}</span>
                                  </small>
                              }
                              DKK {
                                formatCurrency(
                                  getUnitCartItemDiscount(item, promotion, new Date()),
                                )
                              }
                              <small>
                                {' X '} <span className={classes.light}>{item.count}</span>
                              </small>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                  <Divider />
                  <List>
                    {
                      this.renderDeliveryInfo()
                    }
                    {
                      !isEmpty(selectedCoupon) &&
                        <React.Fragment>
                          <ListItem className={classes.listItem}>
                            <ListItemText primary="Sum priser" />
                            <ListItemText
                              primary={`${formatCurrency(discountedPrice)}`}
                              className={classes.price}
                            />
                          </ListItem>
                          <ListItem className={classes.listItem}>
                            <ListItemText primary="Kupon" />
                            <ListItemText
                              primary={`- ${formatCurrency(discountedPrice - amountWithCoupon)}`}
                              className={classes.price}
                            />
                          </ListItem>
                        </React.Fragment>
                    }
                    <ListItem className={classes.listItem}>
                      <ListItemText primary="Total Priser" />
                      <ListItemText
                        primary={`DKK ${formatCurrency(amountWithCoupon + orderinfo.delivery_amount)}`}
                        className={classes.price}
                      />
                    </ListItem>
                    {
                      agree &&
                        <ListItem className={classes.listItem}>
                          <ListItemText primary="Håndterings Gebyr" />
                          <ListItemText
                            primary={'DKK 1.89'}
                            className={classes.price}
                          />
                        </ListItem>
                    }
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText align="center">
                        <Typography>
                          {store.address}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      {
                        getDeliveryWayText(orderinfo.order_type, orderinfo.plan_time)
                      }
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Footer />
        <OrderDetailModal
          open={isOpenDetailModal}
          dish={currentDish}
          onClose={() => this.setState({ isOpenDetailModal: false })}
          promotion={promotion}
        />
      </main>
    );
  }
}


Purchase.propTypes = {
  classes: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  setOrderinfo: PropTypes.func.isRequired,
  orderinfo: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  diner: PropTypes.shape().isRequired,
  delivery: PropTypes.shape().isRequired,
  router: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  couponPromotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  coupon: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  configuration: PropTypes.shape().isRequired,
  fetchCoupon: PropTypes.func.isRequired,
  fetchCouponPromotion: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    orderinfo: state.orderinfo,
    cart: state.cart,
    store: state.store,
    diner: state.diner,
    router: state.router,
    delivery: state.delivery,
    promotion: state.promotion,
    couponPromotion: state.couponPromotion,
    coupon: state.coupon,
    auth: state.auth,
    configuration: state.configuration,
  }),
  dispatch => ({
    ...bindActionCreators({
      setOrderinfo,
      fetchCoupon,
      fetchCouponPromotion,
    }, dispatch),
    dispatch,
  }),
)(withStyles(styles)(Purchase));
