
export default theme => ({
  footer: {
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 10,
    paddingBottom: 36,
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
  },
  img: {
    width: 30,
    marginLeft: 10,
    marginRight: 10,
  },
});
