
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CookieConsent from 'react-cookie-consent';
import { isEmpty, get } from 'lodash';
import styles from './styles';

function TermLink({ store, classes }) {
  if (isEmpty(get(store, 'epay_term_href', ''))) {
    return (
      <Link to={{ pathname: '/term' }} className={classes.light}> Læs Mere </Link>
    );
  }
  return (
    <a href={store.epay_term_href} target="_blank" rel="noopener noreferrer" className={classes.light}> Læs Mere </a>
  );
}

TermLink.propTypes = {
  store: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
};

class CookieConsentBar extends React.PureComponent {
  render() {
    const { classes, store } = this.props;
    return (
      <CookieConsent
        buttonText="Acceptér"
        className={classes.button}
        expires={180}
      >
        Ved at benytte denne hjemmeside, accepterer du, at vi bruger cookies.
        <TermLink classes={classes} store={store} > Læs Mere </TermLink>
      </CookieConsent>
    );
  }
}

CookieConsentBar.propTypes = {
  classes: PropTypes.shape().isRequired,
  store: PropTypes.shape({}),
};

CookieConsentBar.defaultProps = {
  store: {},
};

export default withStyles(styles)(CookieConsentBar);
