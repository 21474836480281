import { colors } from 'themes/variables';
import { mediaMedium, lineClamp } from 'themes/mixins';

const baseExtraMidiaMixin = theme => ({
  content: {
    display: 'flex',
  },
  media: {
    height: 140,
    paddingTop: 0,
  },
  price: {
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  originPrice: {
  },
  contentInner: {
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
});

export default theme => ({
  card: {
    margin: '16px 16px',
  },
  media: {
    width: '100%',
    minHeight: '180px',
    maxHeight: '180px',
    cursor: 'pointer',
  },
  header: {
    padding: 0,
  },
  headerTitle: {
    fontWeight: 500,
    fontSize: '1.15rem',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  action: {
    padding: 0,
    float: 'right',
  },
  addIcon: {
    fontSize: '150%',
    color: theme.palette.primary.main,
  },
  addIconMore: {
    fontSize: '150%',
    color: theme.palette.success.main,
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  contentInner: {
    flex: '1 1 auto',
    padding: theme.spacing(),
    paddingTop: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  desc: {
    fontSize: '0.8rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    ...lineClamp(3),
  },
  originPrice: {
    marginRight: theme.spacing(2),
    color: colors.secondary,
    display: 'inline-block',
  },
  price: {
    color: colors.focus,
    display: 'inline-block',
  },
  priceWrapper: {
  },
  modalImage: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 400,
    margin: 'auto',
  },
  promotionTip: {
    position: 'absolute',
    paddingTop: `${theme.spacing() / 2}px`,
    paddingBottom: `${theme.spacing() / 2}px`,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    borderRadius: '0 0px 3px 0',
  },
  ...mediaMedium({
    desc: {
      display: 'block',
      minHeight: 58,
      maxHeight: 600,
      ...lineClamp(3),
    },
    headerTitle: {
      minHeight: 58,
      fontSize: '1.15rem',
      ...lineClamp(2),
    },
    ...baseExtraMidiaMixin(theme),
  }),
});
