import React from 'react';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SignForm from 'components/containers/forms/Signup';
import { signup, addDiner } from 'apis/services/signup';
import { push } from 'connected-react-router';
import { message } from 'antd';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import styles from './styles';

class Signin extends React.PureComponent {
  async submit(values) {
    const signupParams = Object.assign(
      pick(values, ['email', 'password1', 'password2']),
      {
        username: values.email,
      },
    );
    const signupResData = await signup(signupParams)
      .then(res => res.data)
      .catch((error) => {
        const errorInfo = error.response.data;
        const keys = Object.keys(errorInfo);
        const usernameIndex = keys.indexOf('username');
        const emailIndex = keys.indexOf('email');
        if (usernameIndex > 0) {
          message.error(errorInfo[keys[emailIndex]][0]);
        } else {
          message.error(errorInfo[keys[0]][0]);
        }
      });
    if (signupResData != null) {
      const addDinerParams = Object.assign(
        pick(values, ['name', 'phone_number']),
        {
          user: signupResData.user.pk,
        },
      );
      addDiner(addDinerParams).then(() => {
        this.props.dispatch(push({
          pathname: '/signin',
        }));
        message.success('Register successfully, you can sign in now.');
      }).catch((error) => {
        const errorInfo = error.response.data;
        const keys = Object.keys(errorInfo);
        message.error(errorInfo[keys[0]][0]);
      });
    }
  }

  render() {
    const { classes, store } = this.props;
    return (
      <main className={classes.wrapper}>
        <Topbar />
        <div className={classes.content}>
          <div className={classes.formWrapper}>
            <SignForm
              onSubmit={values => this.submit(values)}
              store={store}
            />
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}

Signin.propTypes = {
  classes: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  store: PropTypes.shape().isRequired,
};

export default connect(
  state => ({
    store: state.store,
  }),
  dispatch => ({
    dispatch,
  }),
)(withStyles(styles)(Signin));
