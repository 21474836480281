import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CategoryList from 'views/index/templates/vanilla/components/CategoryList';
import styles from './styles.js';

const CategorySidebar = props => (
  <div
    className={props.classes.wrapper}
    onScroll={props.onScroll}
  >
    <CategoryList {...props.listProps} />
  </div>
);

CategorySidebar.propTypes = {
  classes: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
  onScroll: PropTypes.func,
  listProps: PropTypes.shape({
    onChange: PropTypes.func,
    category: PropTypes.arrayOf(PropTypes.shape()),
    rootEl: PropTypes.string,
    prefix: PropTypes.string,
    scrollSpyNameList: PropTypes.arrayOf(PropTypes.string),
  }),
};

CategorySidebar.defaultProps = {
  onChange: () => null,
  onScroll: e => e.stopPropagation,
  listProps: {
    onChange: () => null,
    category: [],
    rootEl: 'body',
    prefix: '',
    scrollSpyNameList: [],
  },
};

export default withStyles(styles)(CategorySidebar);
