import { colors } from 'themes/variables';
import { mediaLarge } from 'themes/mixins';

export default theme => ({
  wrapper: {
    position: 'fixed',
    width: '100vw',
    bottom: 0,
    left: 0,
    // backgroundColor: theme.palette.background.secondary,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.appBar,
    boxShadow: theme.shadows[2],
    borderTop: `1px solid ${colors.undertone}`,
  },
  listItem: {
    display: 'flex',
    flexDrection: 'colmn',
    justifyContent: 'space-between',
  },
  iconWrap: {
    marginRight: 0,
    color: theme.palette.primary.main,
  },
  badge: {
    marginRight: 12,
  },
  ...mediaLarge({
    wrapper: {
      display: 'none',
    },
  }),
});
