
export default theme => ({
  formWrapper: {
    maxWidth: 600,
  },
  light: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
});
