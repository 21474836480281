
import { footerHeight } from 'themes/variables';
import { wrapper } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...wrapper(),
  },
  content: {
    minHeight: `calc(100vh - ${footerHeight}px)`,
    paddingTop: 56,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  formWrapper: {
    maxWidth: 450,
    width: '100%',
    alignSelf: 'center',
  },
});
