
export default theme => ({
  title: {
    fontSize: '500%',
    color: theme.palette.primary.main,
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  forgot: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  padding: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  middle: {
    textAlign: 'center',
  },
  light: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});
