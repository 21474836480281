import { mediaLarge } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'none',
  },
  head: theme.mixins.toolbar,
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconWrap: {
    marginRight: 0,
    color: theme.palette.primary.main,
    display: 'block',
    textAlign: 'center',
  },
  badge: {
    marginRight: 12,
  },
  title: {
    width: '35%',
    marginRight: 10,
    padding: 0,
    wordBreak: 'break-word',
  },
  price: {
    width: '35%',
    textAlign: 'right',
    padding: 0,
  },
  count: {
    width: '30%',
    textAlign: 'center',
  },
  submit: {
    width: '100%',
  },
  more: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  ...mediaLarge({
    wrapper: {
      display: 'block',
      boxShadow: theme.shadows[1],
    },
  }),
});
