import { mediaLarge } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'flex',
  },
  head: theme.mixins.toolbar,
  active: {
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0,
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemText: {
    margin: 0,
  },
  ...mediaLarge({
    wrapper: {
      display: 'block',
      minWidth: '200px',
      maxWidth: '250px',
    },
    item: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
    itemText: {
      marginTop: theme.spacing() / 2,
      marginBottom: theme.spacing() / 2,
    },
  }),
});
