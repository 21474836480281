import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import formatCurrency from 'utils/formatCurrency';
import styles from './styles.js';

function isShowProperty(sku) {
  const properties = get(sku, 'properties', [{}]);
  const key = get(properties[0], 'key', '');
  return !(properties.length === 1 && key === 'default');
}

function isShowAttachProperty(cartItem) {
  const properties = get(cartItem, 'attach_properties', []);
  return properties.length > 0;
}

class OrderDetailModal extends React.PureComponent {
  render() {
    const { open, onClose, classes, dish, unitPrice } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.paper,
        }}
      >
        {
          !isEmpty(dish.name) &&
          <DialogTitle classes={{ root: classes.title }}>{dish.name} Detaljer</DialogTitle>
        }
        <DialogContent className={classes.content}>
          {
            isShowProperty(dish) && dish.properties.map(item => (
              <React.Fragment key={`${item.key}-${item.value}`}>
                <div className={classes.properties}>
                  <Typography variant="button" gutterBottom>{item.key}: </Typography>
                  <Typography variant="button" className={classes.propertyValue}>{item.value}</Typography>
                </div>
              </React.Fragment>
            ))
          }
          <br />
          {
            isShowAttachProperty(dish) && dish.attach_properties.map(item => (
              <React.Fragment key={`${item.key}-${item.value}`}>
                <div className={classes.properties}>
                  <Typography variant="button" gutterBottom>{item.key}: </Typography>
                  <Typography variant="button" className={classes.propertyValue}>{item.value}</Typography>
                </div>
              </React.Fragment>
            ))
          }
          {
            !isEmpty(dish.orderpart_set) &&
            <Table className={classes.extrasTable}>
              <TableHead>
                <TableRow>
                  <TableCell component="th">{`Antal Mennesker (${dish.count} Person)`}</TableCell>
                  <TableCell component="th">Tælle</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dish.orderpart_set.map(item => (
                  <TableRow key={`part_${item.id}`}>
                    <TableCell scope="row" className={classes.cell}>
                      {item.name}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <small> {item.count}</small>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
          {
            !isEmpty(dish.orderextra_set) &&
            <Table className={classes.extrasTable}>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Extras</TableCell>
                  <TableCell component="th">Pris</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dish.orderextra_set.map(item => (
                  <TableRow key={`extra_${item.id}`}>
                    <TableCell scope="row" className={classes.cell}>
                      {item.name}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {formatCurrency(item.price)}
                      <small> X {item.count}</small>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Typography variant="button" className={classes.unitPrice}>
            DKK {formatCurrency(unitPrice)}
             X
            {dish.count}
          </Typography>
          <Button onClick={onClose} color="primary">
            LUK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

OrderDetailModal.propTypes = {
  classes: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dish: PropTypes.shape().isRequired,
  unitPrice: PropTypes.number.isRequired,
};

OrderDetailModal.defaultProps = {
  open: false,
  onClose: () => null,
  dish: {
    properties: [],
    orderextra_set: [],
    attach_properties: [],
  },
  unitPrice: 0,
};

export default withStyles(styles)(OrderDetailModal);
