const INIT_CONFIGURATION_SUCCESS = Symbol('INIT_CONFIGURATION_SUCCESS');
export const actionTypes = {
  INIT_CONFIGURATION_SUCCESS,
};

function createInitialState() {
  return {
    status: 'open',
    opening_hours: {
      Fri: {
        endtime: '23:59:59',
        starttime: '00:00:00',
      },
      Mon: {
        endtime: '23:59:59',
        starttime: '00:00:00',
      },
      Sat: {
        endtime: '23:59:59',
        starttime: '00:00:00',
      },
      Sun: {
        endtime: '23:59:59',
        starttime: '00:00:00',
      },
      Thu: {
        endtime: '23:59:59',
        starttime: '00:00:00',
      },
      Tue: {
        endtime: '23:59:59',
        starttime: '00:00:00',
      },
      Wed: {
        endtime: '23:59:59',
        starttime: '00:00:00',
      },
    },
    message: '',
    net_pay_only: false,
    delivery_waiting_duration: 45,
    carryout_waiting_duration: 45,
    carrierbags_in_force: true, // the default value is false
    template_name: 'default', // 'vanilla',
  };
}

export const initialState = createInitialState();

export const initConfigurationSuccess = payload => ({
  type: INIT_CONFIGURATION_SUCCESS,
  payload,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
