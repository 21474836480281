export default theme => ({
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  padding: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  middle: {
    textAlign: 'center',
  },
  light: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  submit: {
    marginTop: 20,
    marginBottom: 20,
  },
  label: {
    marginTop: 20,
  },
});
