import React from 'react';
import moment from 'moment';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import OfflineModal from 'components/presentations/OfflineModal';
import PurchaseDrawer from 'components/presentations/PurchaseDrawer';
import PurchaseModal from 'components/presentations/PurchaseModal';
import CartBottomBar from 'components/presentations/CartBottomBar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { closeNonocoTips } from 'status/partial/ui/showtips';
import { fetchCategory } from 'status/partial/category';
import { fetchMenu, getDefaultMenuItem, findCarrierBag } from 'status/partial/menu';
import { fetchPromotion } from 'status/partial/promotion';
import {
  addDish,
  addMultipleDish,
  removeDish,
  mapItemToCart,
  isCarrierBagInCart,
} from 'status/partial/cart';
import { isEmpty, get, isNumber, groupBy, keys } from 'lodash';
import { message, notification } from 'antd';

import SigninModal from 'views/index/templates/vanilla/components/SigninModal';
import CategoryTopBar from 'views/index/templates/vanilla/components/CategoryTopBar';
import CategorySidebar from 'views/index/templates/vanilla/components/CategorySidebar';
import MerchandiseList from 'views/index/templates/vanilla/components/MerchandiseList';
import ExtrasModal from 'views/index/templates/vanilla/components/ExtrasModal';
import FloatCart from 'views/index/templates/vanilla/components/FloatCart';
import NonocoTipsModal from 'views/index/templates/vanilla/components/NonocoTipsModal';
import {
  hasSecondaryMenu,
  isEnableTheCarrierBagFeature,
  initCarrierBagInCart,
} from 'views/index/templates/utils/menutools';
import styles from './styles';

const CATEGORY_PREFIX = 'category_';
const MERCHANDISE_WRAPPER_SUFFIX = '_merchandise_wrapper';

function makeMenu(state) {
  return groupBy(state.menu.map(merchandise => ({
    merchandise,
  })), 'merchandise.store_category.id');
}

// Don't show any empty categories.
export function filterCategory(menu, category) {
  return category.filter(item => get(menu[item.id], 'length', 0) > 0);
}

// Sort the keys of merchandise list for fit the category list order.
// The category list order was provided from the server side.
export function getSortedMenuKeys(menu) {
  return keys(menu).sort((a, b) => {
    const firstRank = get(menu[a], '0.merchandise.store_category.ranking_index', 1000000);
    const secondRank = get(menu[b], '0.merchandise.store_category.ranking_index', 1000000);
    return firstRank - secondRank;
  });
}

// The third-party library called 'react-scrollspy' needs these ids.
export function extractCategoryId(
  menu,
  cateforyPrefix = 'category_',
  merchandiseWrapperSuffix = '_merchandise_wrapper',
) {
  return getSortedMenuKeys(menu).map(id => `${cateforyPrefix}${id}${merchandiseWrapperSuffix}`);
}

class Index extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSigninModalOpen: false,
      isExtrasModalOpen: false,
      isPurchaseOpen: false,
      currentMerchandise: getDefaultMenuItem(),
    };
  }

  componentDidMount() {
    const nowUTC = moment().toISOString();
    this.props.fetchCategory({ store: this.props.store.id });
    this.props.fetchMenu({ store: this.props.store.id });
    this.props.fetchPromotion({
      store: this.props.store.id,
      start_time: nowUTC,
      end_time: nowUTC,
    });
  }

  componentDidUpdate() {
    const { menu, configuration, dispatch, cart } = this.props;
    const carrierBagSPU = findCarrierBag(menu);
    if (
      isEnableTheCarrierBagFeature(
        carrierBagSPU,
        get(configuration, 'carrierbags_in_force', false),
      )
      &&
      !isCarrierBagInCart(cart)
    ) {
      initCarrierBagInCart(carrierBagSPU, dispatch);
    }
  }

  onAdd = (item) => {
    // secondary menu
    if (hasSecondaryMenu(item)) {
      this.setState({ isExtrasModalOpen: true, currentMerchandise: item });
      return;
    }

    // without secondary menu
    this.addDishToCart(mapItemToCart(item.merchandisespec_set[0], item.store_category));
  }

  onCardClick = (item) => {
    this.setState({ isExtrasModalOpen: true, currentMerchandise: item });
  }

  tagChange = (e) => {
    const targetElm = document.querySelector(`#${e.currentTarget.id}${MERCHANDISE_WRAPPER_SUFFIX}`);
    if (!isEmpty(targetElm)) {
      targetElm.scrollIntoView();
      window.scrollBy(0, -56);
    }
  }

  addDishToCart = (dish, count) => {
    if (isNumber(count)) {
      this.props.addMultipleDish({
        dish,
        count,
      });
    } else {
      this.props.addDish(dish);
    }
    notification.success({
      message: 'Varen er nu tilføjet til kurven!',
      duration: 2,
    });
  }

  next = () => {
    const { cart, auth } = this.props;
    if (cart.length === 0) {
      message.warning('Indkøbskurven er tom.');
      return;
    }
    if (isEmpty(auth)) {
      this.props.dispatch(push({
        pathname: '/signin',
        search: '?to=/shipping',
      }));
      return;
    }
    this.props.dispatch(push({
      pathname: '/shipping',
    }));
  }

  render() {
    const {
      isExtrasModalOpen,
      isPurchaseOpen,
      currentMerchandise,
      isSigninModalOpen,
    } = this.state;
    const {
      classes,
      store,
      cart,
      promotion,
      configuration,
      isShowNonocoTips,
    } = this.props;
    const menu = this.props.groupedMenu;
    const category = filterCategory(this.props.groupedMenu, this.props.category);

    if (isEmpty(this.props.menu)) {
      return (
        <div
          className="loadingio-eclipse"
        >
          <div className="ldio-rpinwye8j0b">
            <div />
          </div>
        </div>
      );
    }

    return (
      <main className={classes.wrapper}>
        <Topbar
          position="static"
          signup={false}
          menu={false}
          onSigninClick={() => this.setState({ isSigninModalOpen: true })}
        />
        <div
          id="index_content_wrapper"
          className={classes.contentWrapper}
        >
          <div className={classes.content}>
            <CategoryTopBar
              listProps={{
                rootEl: '#index_content_wrapper',
                category,
                prefix: CATEGORY_PREFIX,
                onChange: this.tagChange,
                scrollSpyNameList: extractCategoryId(
                  menu,
                  CATEGORY_PREFIX,
                  MERCHANDISE_WRAPPER_SUFFIX,
                ),
              }}
            />
            <aside className={classes.asideLeft}>
              <CategorySidebar
                listProps={{
                  rootEl: '#index_content_wrapper',
                  category,
                  prefix: CATEGORY_PREFIX,
                  onChange: this.tagChange,
                  scrollSpyNameList: extractCategoryId(
                    menu,
                    CATEGORY_PREFIX,
                    MERCHANDISE_WRAPPER_SUFFIX,
                  ),
                }}
              />
            </aside>
            <div className={classes.cardWrapper}>
              <MerchandiseList
                menu={menu}
                onAdd={this.onAdd}
                onShowInfo={this.onCardClick}
                promotion={promotion}
                prefix={CATEGORY_PREFIX}
                suffix={MERCHANDISE_WRAPPER_SUFFIX}
              />
            </div>
          </div>
          <Footer />
        </div>
        <CartBottomBar
          onClick={() => this.setState({ isPurchaseOpen: true })}
          cart={cart}
          promotion={promotion}
        />
        <PurchaseDrawer
          open={isPurchaseOpen}
          onClose={() => this.setState({ isPurchaseOpen: false })}
          onSubmit={this.next}
          deliveryAllow={store.is_delivery}
        />
        <ExtrasModal
          open={isExtrasModalOpen}
          onClose={() => this.setState({ isExtrasModalOpen: false })}
          add={this.addDishToCart}
          cart={cart}
          merchandise={currentMerchandise}
          promotion={promotion}
          title={currentMerchandise.name}
          category={get(this.props, 'category', [])}
        />
        <OfflineModal
          open={get(configuration, 'status', 'open') === 'close'}
          onClose={() => {
            this.setState({
              isOfflineOpen: false,
            });
          }}
          email={store.main_email}
          phone={store.main_phone_number}
        />
        <FloatCart
          onClick={() => this.setState({ isPurchaseOpen: true })}
          cart={cart}
        />
        <PurchaseModal
          open={isPurchaseOpen}
          onClose={() => this.setState({ isPurchaseOpen: false })}
          onSubmit={this.next}
          deliveryAllow={store.is_delivery}
        />
        <SigninModal
          open={isSigninModalOpen}
          onClose={() => this.setState({ isSigninModalOpen: false })}
          submit={this.signinSubmit}
        />
        <NonocoTipsModal
          open={isShowNonocoTips}
          content={store.nonoco_tips}
          onClose={() => this.props.closeNonocoTips()}
        />
      </main>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  fetchCategory: PropTypes.func.isRequired,
  fetchMenu: PropTypes.func.isRequired,
  fetchPromotion: PropTypes.func.isRequired,
  addDish: PropTypes.func.isRequired,
  addMultipleDish: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  closeNonocoTips: PropTypes.func.isRequired,
  category: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  store: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  menu: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  groupedMenu: PropTypes.PropTypes.shape().isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  configuration: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  isShowNonocoTips: PropTypes.bool.isRequired,
};

export default connect(state => ({
  auth: state.auth,
  category: state.category,
  store: state.store,
  cart: state.cart,
  promotion: state.promotion,
  menu: state.menu,
  groupedMenu: makeMenu(state),
  configuration: state.configuration,
  isShowNonocoTips: state.ui.showtips,
}), dispatch => ({
  fetchCategory: bindActionCreators(fetchCategory, dispatch),
  fetchMenu: bindActionCreators(fetchMenu, dispatch),
  fetchPromotion: bindActionCreators(fetchPromotion, dispatch),
  addDish: bindActionCreators(addDish, dispatch),
  addMultipleDish: bindActionCreators(addMultipleDish, dispatch),
  removeDish: bindActionCreators(removeDish, dispatch),
  closeNonocoTips: bindActionCreators(closeNonocoTips, dispatch),
  dispatch,
}))(withStyles(styles)(Index));
