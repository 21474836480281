import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CartContent from 'components/presentations/CartContent';
import { omit } from 'lodash';
import styles from './styles.js';

const PurchaseModal = (props) => {
  const { classes, open, onClose } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.wrapper}
    >
      <DialogContent className={classes.content}>
        <CartContent {...omit(props, ['classes', 'onClose', 'open'])} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Luk
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PurchaseModal.propTypes = {
  classes: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PurchaseModal.defaultProps = {
  open: true,
  onClose: () => null,
};

export default withStyles(styles)(PurchaseModal);
