import React from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import { isEmpty, map, get, isEqual } from 'lodash';
import styles from './styles.js';

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.spy = React.createRef();
  }
  shouldComponentUpdate(nextProps) {
    const { category, scrollSpyNameList } = this.props;
    const categoryList = category;
    const categoryIdList = map(categoryList, 'id').sort();
    const nextCategoryLst = get(nextProps, 'category', []);
    const nextCategoryIdList = map(nextCategoryLst, 'id').sort();
    const nextScrollSpyNameList = get(nextProps, 'scrollSpyNameList', []);

    if (!isEqual(nextCategoryIdList, categoryIdList)) {
      return true;
    }
    if (!isEqual(scrollSpyNameList, nextScrollSpyNameList)) {
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    // Prevent component 'Scrollspy' can't find the DOMElement which specific by its prop 'items'
    // before the DOMElement renders ready.
    setTimeout(() => {
      this.spy.current._initFromProps();
    }, 300);
  }

  onSpyEvent = (el) => {
    const categoryId = el.getAttribute('data-category');
    if (isEmpty(categoryId)) {
      return;
    }
    const targetCategoryItem = document.getElementById(`category_${categoryId}`);
    if (isEmpty(targetCategoryItem)) {
      return;
    }
    targetCategoryItem.scrollIntoView();
  }

  render() {
    const {
      category,
      scrollSpyNameList,
      classes,
      onChange,
      prefix,
      rootEl,
    } = this.props;

    return (
      <React.Fragment>
        {
          !isEmpty(category) &&
            <Scrollspy
              rootEl={rootEl}
              items={scrollSpyNameList}
              currentClassName={classes.active}
              componentTag={topbarprops => (
                <List
                  className={classes.wrapper}
                >
                  {topbarprops.children}
                </List>
              )}
              onUpdate={this.onSpyEvent}
              ref={this.spy}
              offset={-56}
            >
              {
                category.map(item => (
                  <ListItem
                    button
                    onClick={onChange}
                    id={`${prefix}${item.id}`}
                    key={`${prefix}${item.id}`}
                    className={
                      classNames({
                        [classes.item]: true,
                      })
                    }
                  >
                    <ListItemText
                      className={classes.itemText}
                    >
                      <span>
                        {item.name}
                      </span>
                    </ListItemText>
                  </ListItem>
                ))
              }
            </Scrollspy>
        }
      </React.Fragment>
    );
  }
}

CategoryList.propTypes = {
  category: PropTypes.arrayOf(PropTypes.shape()),
  scrollSpyNameList: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  rootEl: PropTypes.string,
};

CategoryList.defaultProps = {
  category: [],
  scrollSpyNameList: [],
  onChange: () => null,
  prefix: '',
  rootEl: 'body',
};

export default withStyles(styles)(CategoryList);
