import { getSessionStorage } from 'utils/sessionStorage';

const INIT_SUCCESS = Symbol('INIT_STORE_SUCCESS');
const REMOVE_STORE = Symbol('REMOVE_STORE');
export const actionTypes = {
  INIT_SUCCESS,
  REMOVE_STORE,
};

function createInitialState() {
  const store = getSessionStorage('store');
  if (store != null) {
    return {
      ...JSON.parse(store),
    };
  }
  return {
  };
}

export const initialState = createInitialState();

export const initStoreSuccess = store => ({
  type: INIT_SUCCESS,
  payload: store,
});

export const removeStore = () => ({
  type: REMOVE_STORE,
});

/*
const fakeOpenHours = {
  Fri: {
    endtime: '23:00:01',
    starttime: '00:00:00',
  },
  Mon: {
    endtime: '00:00:01',
    starttime: '00:00:00',
  },
  Sat: {
    endtime: '00:00:01',
    starttime: '00:00:00',
  },
  Sun: {
    endtime: '00:00:01',
    starttime: '00:00:00',
  },
  Thu: {
    endtime: '00:00:01',
    starttime: '00:00:00',
  },
  Tue: {
    endtime: '00:00:01',
    starttime: '00:00:00',
  },
  Wed: {
    endtime: '00:00:01',
    starttime: '00:00:00',
  },
};
*/

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_SUCCESS:
      return {
        ...action.payload,
        // opening_hours: fakeOpenHours,
      };
    case REMOVE_STORE:
      return {};
    default:
      return state;
  }
};
